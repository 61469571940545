/*
  @page tender and card type report
*/

//Import require libraries
import React,{ useEffect, useState,useRef} from "react";
import ChartistGraph from "react-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import {CSVLink, CSVDownload} from 'react-csv';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import RightIcon from '@material-ui/icons/ChevronRight';

// import DateTimePicker from '@mui/lab/DateTimePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment'
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;
  

/*
  @const Page styles
*/
  const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox:{
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      width:"100%",
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  tableleftheader:{
    left:"0px",
    height:"35px",
    lineHeight:"35px",
    textAlign:"left",
    paddingLeft:"5px",
    width:"80px",
    minWidth:"250px",
    overflowX:"hidden",
    backgroundColor:"#E5EFFF",
    position:"sticky",
    borderLeft:"2px solid white",
    borderRight:"2px solid white",
    borderTop:"2px solid white",
    borderBottom:"2px solid white"
  },
  tablerightheader:{
    right:"0px",
    height:"35px",
    lineHeight:"35px",
    textAlign:"right",
    width:"80px",
    minWidth:"250px",
    overflowX:"hidden",
    backgroundColor:"#E5EFFF",
    position:"sticky",
    borderLeft:"2px solid white",
    borderRight:"2px solid white",
    borderTop:"2px solid white",
    borderBottom:"2px solid white"
  },
  tablecenterheader:{
    width:"80px",
    height:"35px",
    lineHeight:"35px",
    textAlign:"right",
    minWidth: "calc(100%/3)",
    backgroundColor:"#E5EFFF",
    borderBottom:"2px solid white",
    borderTop:"2px solid white"
  },
  startDateBtn :{   
    width: "100% !important",
    paddingLeft:"1%",
    '& div:nth-child(1)':{
      width: "100% !important",
    }     
  },
  buttonSearch:{
    width: "100%",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  },
  span:{
    width:"100%",
    paddingRight:"10px"
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


/*
  @function tender and card report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {  
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = React.useState('');
  const [selectEmployee, setEmployee] = React.useState('');
  const [selectDevice, setDevice] = React.useState('');
  const [visibleCardsDiv, setVisibleCardsDiv] = React.useState(false);
  const [visibleCardsTypesDiv, setVisibleCardsTypesDiv] = React.useState(false);
  const [visibleCreditCardsTypesDiv, setVisibleCreditCardsTypesDiv] = React.useState(false);
  const [visibleDebitCardsTypesDiv, setVisibleDebitCardsTypesDiv] = React.useState(false);
  const [totalCash, setTotalCash] = React.useState("0.00");
  const [totalCashTrans, setTotalCashTrans] = React.useState(0);
  const [totalCC, setTotalCC] = React.useState("0.00");
  const [totalCCTrans, setTotalCCTrans] = React.useState(0);
  const [totalDebit, setTotalDebit] = React.useState("0.00");
  const [totalDebitTrans, setTotalDebitTrans] = React.useState(0);
  const [totalCardPayment, setTotalCardPayment] = React.useState(0.00);
  const [totalCardPaymentTrans, setTotalCardPaymentTrans] = React.useState(0);
  const [totalRefundCash, setTotalRefundCash] = React.useState(0.00);
  const [totalRefundCashTrans, setTotalRefundCashTrans] = React.useState(0);
  const [totalRefundCC, setTotalRefundCC] = React.useState(0.00);
  const [totalRefundCCTrans, setTotalRefundCCTrans] = React.useState(0);
  const [totalRefundDebit, setTotalRefundDebit] = React.useState(0.00);
  const [totalRefundDebitTrans, setTotalRefundDebitTrans] = React.useState(0);
  const [totalRefundCardPayment, setTotalRefundCardPayment] = React.useState(0.00);
  const [totalRefundCardPaymentTrans, setTotalRefundCardPaymentTrans] = React.useState(0);  
  const [totalAllTransaction, setTotalAllTransaction] = React.useState(0); 
  const [totalAllSales, setTotalAllSales] = React.useState(0); 
  const [totalOrderRefund, setTotalOrderRefund] = React.useState(0); 
  const [creditCardWiseSummary, setCreditCardWiseSummary] = useState([]);
  const [debitCardWiseSummary, setDebitCardWiseSummary] = useState([]);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [reportStartDate, setReportStartDate] = React.useState();
  const [reportFilter, setReportFilter] = useState({"emp":0,"device":0});
  const [chartHigh, setChartHigh] = useState(70);
  const [csvData, setCsvData] = useState([]);
  const [accessPage, setAccessPage] = useState(1);  
  useEffect(() => {
 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0)
      {
        setAccessPage(0);
        return;
      }
    }

    getReportFilterData();
  }, []);
  
  /*
    @Const generate tender chart
  */
  const tenderChart = {
    data: {
      labels: [
        "Debit Card",
        "Credit Card",
        "Cash",        
      ],
      series: [
        [totalDebit.replace(",",""), totalCC.replace(",",""),totalCash.replace(",","")],
      ]
    },
    options: {
      seriesBarDistance: 40,
      axisX: {
        showGrid: true,
      },
      axisY: {
        showGrid: true,
      },
      low: 0,
      high: chartHigh,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0,
      },  
      height:300,
      plugins: [
        ChartistTooltip({
          appendToBody: true
        })
      ]
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
  
        if (data.type === "bar") {
              data.element.attr({
                 style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
              });        
        }
      },
      
    },
  };
  
  
/*
  @function get filtered report data
*/
  const getReportFilterData = () =>
{
  const data = {};
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
  }    
  data.parent_id = localStorage.getItem('parent_id');
  data.location_id = localStorage.getItem('location_id');
  axios.post(window.apiUrl+'get_report_filter_data', data, {
    headers: headers
  }).then(response => {      
      setEmpDropDownVal(response.data.emp);
      setDevicesDropDownVal(response.data.devices);
      setReportStartDate(response.data.report_start_time.split(":"));
  }).catch(error => {    
    return error;
  });

}
/*
  @Const get employee filtered data
*/
const employeeChange = (event) => {
  setEmployee(event.target.value);
  let filterData = reportFilter;
  filterData["emp"] = event.target.value;
  setReportFilter(filterData);
};

/*
  @Const get device filtered data
*/
const deviceChange = (event) => {
  setDevice(event.target.value);
  let filterData = reportFilter;
  filterData["device"] = event.target.value;
  setReportFilter(filterData);
};
  
/*
  @Const search filtered data
*/
const handleSearch = () =>{  
    const data = { 'start_date': moment(startDate).format('YYYY-MM-DD HH:mm:00'), 'end_date': moment(endDate).format('YYYY-MM-DD HH:mm:59'),'filterData':reportFilter };    
    getReportData(data);   
    
  }

/*
  @Const get all report data
*/
  const getReportData = (data) => {   
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }    
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'merchant_tender_type_report', data, {
      headers: headers
    }).then(response => {      

      const exportArr = [];
      exportArr.push(["Sales by Tender and Card Type Report"]);
      exportArr.push([moment(startDate).format('MMM DD,YYYY hh:mm a')+" - "+moment(endDate).format('MMM DD YYYY hh:mm a')]);
      exportArr.push(["Filters:"]);      
      exportArr.push([""]);
      exportArr.push(["Tender Types","Card Type","Transation","Sales Total","Order Refunds","Amount Collected"]);
      exportArr.push(["Credit Cards + Debit Cards","",response.data.totalSummary.totalCardPaymentTrans,"$"+response.data.totalSummary.totalCardPayment,"$"+response.data.totalSummary.totalRefundCardPayment,"$"+response.data.totalSummary.totalCardPayment]);    
      exportArr.push(["Credit Card","",response.data.totalSummary.totalCCTrans,"$"+response.data.totalSummary.totalCC,"$"+response.data.totalSummary.totalRefundCC,"$"+response.data.totalSummary.totalCC]);
      response.data.creditCardSimpleArr.map((eachDevice, id) => {
        exportArr.push(["",eachDevice.cardType,eachDevice.transactions,"$"+eachDevice.amount,"$"+eachDevice.return_amount,"$"+eachDevice.amount]);     
      });
      exportArr.push(["Debit Card","",response.data.totalSummary.totalDebitTrans,"$"+response.data.totalSummary.totalDebit,"$"+response.data.totalSummary.totalRefundDebit,"$"+response.data.totalSummary.totalDebit]);
      response.data.debitCardSimpleArr.map((eachDevice, id) => {
        exportArr.push(["",eachDevice.cardType,eachDevice.transactions,"$"+eachDevice.amount,"$"+eachDevice.return_amount,"$"+eachDevice.amount]);     
      });
      exportArr.push(["Cash","",response.data.totalSummary.totalCashTrans,"$"+response.data.totalSummary.totalCash,"$"+response.data.totalSummary.totalRefundCash,"$"+response.data.totalSummary.totalCash]);  
      exportArr.push(["TOTAL","",response.data.totalSummary.totalAllTrans,"$"+response.data.totalSummary.totalAll,"$"+response.data.totalSummary.totalRefundAll,"$"+response.data.totalSummary.totalAll]);
      setCsvData(exportArr);


      setTableData((response.data.cardWiseSummary.hasOwnProperty("sale"))?response.data.cardWiseSummary.sale.credit:[]);
      setTotalCC(response.data.totalSummary.totalCC);
      setTotalCCTrans(response.data.totalSummary.totalCCTrans);
      setTotalDebit(response.data.totalSummary.totalDebit);
      setTotalDebitTrans(response.data.totalSummary.totalDebitTrans);
      setTotalCardPayment(response.data.totalSummary.totalCardPayment);
      setTotalCardPaymentTrans(response.data.totalSummary.totalCardPaymentTrans);
      setTotalCash(response.data.totalSummary.totalCash);      
      setTotalCashTrans(response.data.totalSummary.totalCashTrans);      
      setTotalRefundCC(response.data.totalSummary.totalRefundCC);
      setTotalRefundCCTrans(response.data.totalSummary.totalRefundCCTrans);
      setTotalRefundDebit(response.data.totalSummary.totalRefundDebit);
      setTotalRefundDebitTrans(response.data.totalSummary.totalRefundDebitTrans);  
      setTotalRefundCardPayment(response.data.totalSummary.totalRefundCardPayment);
      setTotalRefundCardPaymentTrans(response.data.totalSummary.totalRefundCardPaymentTrans);
      setTotalRefundCash(response.data.totalSummary.totalRefundCash);
      setTotalRefundCashTrans(response.data.totalSummary.totalRefundCashTrans);        
      setTotalAllTransaction(response.data.totalSummary.totalAllTrans);
      setTotalAllSales(response.data.totalSummary.totalAll);
      setTotalOrderRefund(response.data.totalSummary.totalRefundAll);
      setCreditCardWiseSummary(response.data.creditCardSimpleArr);
      setDebitCardWiseSummary(response.data.debitCardSimpleArr);
      let max = response.data.totalSummary.totalCC.replace(",","");
      if(max <response.data.totalSummary.totalDebit.replace(",","")) //Check max is smaller than total dabit amount
      {
        max = response.data.totalSummary.totalDebit.replace(",","");
      }
      if(max < response.data.totalSummary.totalCash.replace(",","")) //Check max is smaller than total cash amount
      {
        max = response.data.totalSummary.totalCash.replace(",","");
      }
      setChartHigh(max);
     
    }).catch(error => {      
      return error;
    });
  }

  /*
    @function date filtered data
  */
  const dateChange = (event) => {
    let selected_date_range = event.target.value;

    switch (selected_date_range) {
      case 1:  //Get current day data     
        setStartDate(new Date().setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        setEndDate(new Date());
        break;
      case 2: //Get yesterday data     
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(Yesterday_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));        
        Yesterday_date.setHours(23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59);
        setEndDate(Yesterday_date);
        break;
      case 3: //Get current week data 
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setEndDate(new Date());
        setStartDate(this_week.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 4: //Get last week data 
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(last_week_start_date.getDate() - last_week_start_date.getDay() - 7);
        last_week_start_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0);
        last_week_end_date.setDate(last_week_end_date.getDate() - last_week_end_date.getDay() - 1);
        last_week_end_date.setHours(23 +  parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59)
        setEndDate(last_week_end_date);
        setStartDate(last_week_start_date);
        break;
      case 5: //Get last 7 days data 
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setEndDate(new Date());
        setStartDate(last_7_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 6: //Get current month data 
        let this_month = new Date();
        var firstDay = new Date(this_month.getFullYear(), this_month.getMonth(), 1);
        var lastDay = new Date(this_month.getFullYear(), this_month.getMonth() + 1, 0);

        setEndDate(new Date());
        setStartDate(new Date(this_month.getFullYear(), this_month.getMonth(), 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 7: //Get last month data 
        let last_month = new Date();
        setEndDate(new Date(last_month.getFullYear(), last_month.getMonth(), 0,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
        setStartDate(new Date(last_month.getFullYear(), last_month.getMonth() - 1, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 8: //Get last 30 days data 
        let last_30_day = new Date();
        last_30_day.setDate(last_30_day.getDate() - 29)
        setEndDate(new Date());
        setStartDate(last_30_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 9: //Get last 3 months data 
        let last_3_month = new Date();
        last_3_month.setMonth(last_3_month.getMonth() - 3)
        setEndDate(new Date());
        setStartDate(last_3_month.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 10: //Get current year data 
          let this_year = new Date();                              
          this_year = new Date(this_year.getFullYear(), 0, 1);
          setStartDate(this_year.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
          setEndDate(new Date());
      break;
      case 11: //Get last year data 
          let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);          
          setEndDate(new Date(lastyear.getFullYear(), 11, 31,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
          setStartDate(new Date(lastyear.getFullYear(), 0, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
      break;
      case 12:          
          setEndDate("");
          setStartDate("");  
      break;   
      default:
        break;
    }    
    setDateRange(event.target.value);
  };
  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Reporting - Sales by Tender and Card Type</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>Reporting - Sales by Tender and Card Type</h4>
          <p className={classes.cardCategoryWhite}></p>
        </CardHeader>
        <CardBody>
              <center>
                <h3>Fetching Data....</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);
  }

  return (
    <GridContainer>      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Sales by Tender and Card Type</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
              
              {/* 
                * Filter options
              */}
              <GridContainer>
                <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={dateRange}
                      label="Date Range"
                      onChange={dateChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>Today</MenuItem>
                      <MenuItem value={2}>Yesterday</MenuItem>
                      <MenuItem value={3}>This Week</MenuItem>
                      <MenuItem value={4}>Last Week</MenuItem>
                      <MenuItem value={5}>Last 7 Days</MenuItem>
                      <MenuItem value={6}>This Month</MenuItem>
                      <MenuItem value={7}>Last Month</MenuItem>
                      <MenuItem value={8}>Last 30 Days</MenuItem>
                      <MenuItem value={9}>Last 3 Months</MenuItem>
                      <MenuItem value={10}>This Year</MenuItem>
                      <MenuItem value={11}>Last Year</MenuItem>
                      <MenuItem value={12}>Custom Date Range</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="giemployee" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Employees</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectEmployee}
                      label="Employees"
                      onChange={employeeChange}
                    >
                      <MenuItem value={0}>All Employees</MenuItem>
                      {empDropDownVal.map((eachEmp, id) => {
                              return <MenuItem value={eachEmp.user_id}>{eachEmp.employee_name}</MenuItem>
                          })
                        }  
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="gidevice" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Devices</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="chart"
                      value={selectDevice}
                      label="Devices"
                      onChange={deviceChange}
                    >
                     <MenuItem value={0}>All Devices</MenuItem>
                  {devicesDropDownVal.map((eachDevice, id) => {
                          return <MenuItem value={eachDevice.serial_number}>{eachDevice.serial_number} ({eachDevice.nick_name})&nbsp; {(parseInt(eachDevice.is_approve) === 0)? <i> - Inactive</i>:""}</MenuItem>
                      })
                    }             
                      </Select>
                    </FormControl>
                  </GridItem>                
                </GridContainer>
              
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}} >
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker   
                                
                    label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                    </LocalizationProvider>
                    </FormControl>
                  </GridItem>                
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>            
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker        
                    label="End Date"        
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                      Search
                    </Button>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                  <CSVLink data={csvData} color="primary" >
                  <Button id="btnGreenAdd" variant="contained" color="primary"  className={`${classes.button} ${classes.buttonSearch}`}>
                      Export
                  </Button>
                  </CSVLink>
                   </FormControl>
                  </GridItem>
                </GridContainer><br></br><br></br>

          <GridContainer>
          
          {/* 
            * Display tender chart
          */}
          <div class="renderChart" style={{width:"100%",marginTop:"30px"}}>
                      <ChartistGraph
                      className="ct-chart"
                      data={tenderChart.data}
                      type="Bar"
                      options={tenderChart.options}
                      responsiveOptions={tenderChart.responsiveOptions}
                      listener={tenderChart.animation}
                    />
                  </div>

          </GridContainer>

          <GridContainer >
            <div class="reactTable" style={{marginTop:"50px",position:"relative",flexDirection:"column",display:"flex",width:"100%"}}>
              <div class="rtTable" style={{width:"100%", overflowX:"scroll",flexDirection:"column",display:"flex"}}>
                  <div class="rthead" style={{minWidth:"480px",display:"flex" }}>
                      <div class="rttr" style={{display:"inline-flex",display:"inline-flex",width:"100%"}}>
                          <div style={{minWidth:"25%"}} class="ttth" className={classes.tableleftheader}>Tender Types</div>
                          <div style={{width:"50%",display:"flex"}}>
                          <div class="ttth" className={classes.tablecenterheader} >Transation</div>
                          <div class="ttth" className={classes.tablecenterheader} >Sales Total</div>
                          <div class="ttth" className={classes.tablecenterheader} ><span className={classes.span}>Order Refunds</span></div>
                          </div>
                          <div style={{minWidth:"25%"}} class="ttth" className={classes.tablerightheader}><span className={classes.span}>Amount Collected</span></div>
                      </div> 
                  </div>
                  <div class="rtbody" style={{minWidth:"480px"}}>
                    <div style={{width:"100%"}}>
                      <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                            <div onClick={() => { setVisibleCardsDiv(!visibleCardsDiv) }} class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold",display:"flex"}} ><div style={{paddingTop:"5px"}}>{visibleCardsDiv? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div>Credit Cards + Debit Cards</div></div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{visibleCardsDiv? " ":totalCardPaymentTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{visibleCardsDiv? " ":"$"+totalCardPayment}</div>
                            <div class="rttd" style={{width:" ",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>{visibleCardsDiv? " ":"$"+totalRefundCardPayment}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>{visibleCardsDiv? " ":"$"+totalCardPayment}</span></div>
                      </div>
                    </div>
                    <div style={{display: visibleCardsDiv ? 'block' : 'none',width:"100%"}}>
                      <div class="rttrgroup even" style={{display:"inline-flex",width:"100%"}}>
                            <div onClick={() => { setVisibleCreditCardsTypesDiv(!visibleCreditCardsTypesDiv) }} class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold",display:"flex"}}><div style={{paddingTop:"5px"}}>{visibleCreditCardsTypesDiv? <DownIcon style={{paddingLeft:"10px"}}></DownIcon>:<RightIcon style={{paddingLeft:"10px"}}></RightIcon>}</div><div>Credit Card</div></div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{visibleCreditCardsTypesDiv? " ":totalCCTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{visibleCreditCardsTypesDiv? " ":"$"+totalCC}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>{visibleCreditCardsTypesDiv? " ":"$"+totalRefundCC}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>{visibleCreditCardsTypesDiv? " ":"$"+totalCC}</span></div>
                      </div>
                    </div>                   

                    {creditCardWiseSummary.map((eachDevice, id) => {
                          return <div style={{display: visibleCreditCardsTypesDiv ? 'block' : 'none',width:"100%"}}>
                          <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                                <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}><span style={{marginLeft:"50px"}}>{eachDevice.cardType}</span></div>
                                <div style={{width:"50%",display:"flex"}}>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{eachDevice.transactions}</div>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${eachDevice.amount}</div>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${eachDevice.return_amount}</span></div>
                                </div>
                                <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${eachDevice.amount}</span></div>
                          </div>                          
                        </div>
                      })
                    }  
                    
                    <div style={{display: visibleCreditCardsTypesDiv ? 'block' : 'none',width:"100%"}}>
                      <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                            <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}><span style={{marginLeft:"50px"}}>Total Credit Card</span></div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{totalCCTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${totalCC}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${totalRefundCC}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${totalCC}</span></div>
                      </div>
                    </div>

                    <div style={{display: visibleCardsDiv ? 'block' : 'none',width:"100%"}}>
                      <div class="rttrgroup even" style={{display:"inline-flex",width:"100%"}}>
                            <div onClick={() => { setVisibleDebitCardsTypesDiv(!visibleDebitCardsTypesDiv) }} class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold",display:"flex"}}><div style={{paddingTop:"5px"}}>{visibleDebitCardsTypesDiv? <DownIcon style={{paddingLeft:"10px"}}></DownIcon>:<RightIcon style={{paddingLeft:"10px"}}></RightIcon>}</div><div>Debit Card</div></div>
                            <div style={{width:"50%",display:"flex"}}>
							              <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth:"calc(100%/3)"}}>{visibleDebitCardsTypesDiv? " ":totalDebitTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth:"calc(100%/3)"}}>{visibleDebitCardsTypesDiv? " ":"$"+totalDebit}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth:"calc(100%/3)"}}> <span className={classes.span}>{visibleDebitCardsTypesDiv? " ":"$"+totalRefundDebit}</span></div>
                             </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>{visibleCardsTypesDiv? " ":"$"+totalDebit}</span></div>

                      </div>
                    </div>
                   

                    {debitCardWiseSummary.map((eachDevice, id) => {
                          return <div style={{display: visibleDebitCardsTypesDiv ? 'block' : 'none',width:"100%"}}>
                          <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                                <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}><span style={{marginLeft:"50px"}}>{eachDevice.cardType}</span></div>
                                <div style={{width:"50%",display:"flex"}}>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{eachDevice.transactions}</div>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${eachDevice.amount}</div>
                                <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${eachDevice.return_amount}</span></div>
                                </div>
                                <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${eachDevice.amount}</span></div>
                          </div>
                          
                        </div>
                      })
                    }  
                    

                    <div style={{display: visibleDebitCardsTypesDiv ? 'block' : 'none',width:"100%"}}>
                      <div class="rttrgroup even" style={{display:"inline-flex",width:"100%"}}>
                            <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}><span style={{marginLeft:"50px"}}>Total Debit Card</span></div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{totalDebitTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${totalDebit}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${totalRefundDebit}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${totalDebit}</span></div>
                      </div>
                    </div>

                    <div style={{display: visibleCardsDiv ? 'block' : 'none',width:"100%"}}>
                      <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                            <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}><span style={{marginLeft:"35px"}}>Total Credit Cards + DebitCards</span></div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{totalCardPaymentTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${totalCardPayment}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${totalRefundCardPayment}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${totalCardPayment}</span></div>
                      </div>
                    </div>
                    <div style={{width:"100%"}}>
                      <div class="rttrgroup even" style={{display:"inline-flex",width:"100%"}}>
                            <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}>Cash</div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{totalCashTrans}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${totalCash}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${totalRefundCash}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${totalCash}</span></div>
                      </div>
                    </div>
                    <div style={{width:"100%"}}>
                      <div class="rttrgroup odd" style={{display:"inline-flex",width:"100%"}}>
                            <div class="rttd" style={{left:"0px",height:"35px",lineHeight:"35px",textAlign:"left",paddingLeft:"5px",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white",fontWeight:"bold"}}>Total</div>
                            <div style={{width:"50%",display:"flex"}}>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>{totalAllTransaction}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}>${totalAllSales}</div>
                            <div class="rttd" style={{width:"80px",height:"35px",lineHeight:"35px",textAlign:"right",minWidth: "calc(100%/3)"}}><span className={classes.span}>${totalOrderRefund}</span></div>
                            </div>
                            <div class="rttd" style={{right:"0px",height:"35px",lineHeight:"35px",textAlign:"right",width:"80px",minWidth:"25%",overflowX:"hidden",position:"sticky",borderLeft:"2px solid white",borderRight:"2px solid white"}}><span className={classes.span}>${totalAllSales}</span></div>
                      </div>
                    </div>                      
                  </div>                  
              </div>
            </div>
          </GridContainer>            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
