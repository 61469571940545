/*
  @page tip list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  verticleLine: {
    height: '116px',
    width: 1,
    backgroundColor: '#DADADA',
    marginRight: '20px',
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function tip page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [tipId, setTipId] = useState("");
  const [tipDeleteDailog, setTipDeleteDailog] = useState(false);
  const [askForTip, setAskForTip] = useState(true);
  const [tipEntryLocation, setTipEntryLocation] = useState("");
  const [tipCalculationType, setTipCalculationType] = useState(1);
  const [tipIncludeTaxInCalculation, setIncludeTaxInCalculation] = useState("");
  const [accessPage, setAccessPage] = useState(1);

  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function For get tips 
  */
  const getTips = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    console.log(headers);
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_tip', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachTip) => {
        var eachTipPercentageAmt = "";
        eachTipPercentageAmt = parseInt(eachTip.tip_percentage) + "%";
        userArr.push([<div style={{ textAlign: 'left' }}>{eachTipPercentageAmt}</div>, eachTip.tip_label, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditTip(eachTip.id, eachTip.tip_label, eachTip.tip_percentage)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setTipId(eachTip.id); setTipDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      setTableData(userArr);
      data.parent_id = localStorage.getItem('parent_id');
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl + 'get_setting', data, {
        headers: headers
      }).then(response => {
        var tip_show = (response.data.settingData.tip_show_on_device) ? "tab" : "receipt";
        var b = 1;
        var tipCheckbox = (response.data.settingData.tip_show_on_receipt == 1) ? true : false;
        setTipCalculationType(b);
        setAskForTip(tipCheckbox);
        handleAskForTipCheckbox(tipCheckbox);
        setTipEntryLocation(tip_show);
        setIncludeTaxInCalculation(response.data.settingData.include_tax_in_tip_calculation);
      }).catch(error => {
        console.log(error);
        return error;
      });
    }).catch(error => {
      console.log(error);
      return error;
    });
  }

  useEffect(() => {
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_tip === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    getTips();
  }, []);


  /*
    @function For set tip values while edit tip 
  */ 
  const handleEditTip = (id, name, amt) => {
    setTipId(id);
    setTipName(name);
    setTipPer(amt);
    setTipPopup(true);
  }

  /*
    @function For empty all tip field 
  */
  const emptyAllField = () => {
    setTipId(0);
    setTipName("");
    setTipPer("");
  }

  /*
    @function For delete tip 
  */
  const handleDeleteTip = () => {
    setTableData(false);
    setTipDeleteDailog(false);
    const data = { id: tipId, name: '', amt: '' };
    console.log(data);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    console.log(headers);
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_tip', data, {
      headers: headers
    }).then(response => {
      console.log(response);
      getTips();
      console.log("ressss", response.data);
      setTipId(0);
      if (response.data.success == true) {
        setSuccess(true);
        setSuccessMsg("Tip Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
      }
    }).catch(error => {
      getTips();
      setTipId(0);
      console.log(error);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }

  const [openAddTip, setTipPopup] = React.useState(false);
  const [tipName, setTipName] = React.useState("");
  const [tipAmount, setTipPer] = React.useState("");

  /*
    @function For save tip 
  */
  const handaleSave = () => {
      const data = {
      tip_show_on_paper: (tipEntryLocation === "receipt") ? 1 : 0,
      tip_show_on_device: (tipEntryLocation === "tab") ? 1 : 0,
      tip_including_tax: tipCalculationType,
      tip_show_on_receipt: (askForTip) ? 1 : 0,
      include_tax_in_tip_calculation: tipIncludeTaxInCalculation,
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    console.log(headers);
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'save_setting', data, {
      headers: headers
    }).then(response => {
      console.log("ressss", response);
      if (response) {
        setSuccessMsg("Save Successfully.");
        setSuccess(true);
      }
    }).catch(error => {
      setErrorMsg("Fail To Save Settings.");
      setError(true);      
      return error;
    });

  }



  /*
    @function For add tips and update tips 
  */
  const saveTip = (id) => {
    setTipPopup(false);
    setTableData(false);
    const data = { id: id, name: tipName, amt: tipAmount };
    console.log(data);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    console.log(headers);
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_tip', data, {
      headers: headers
    }).then(response => {

      console.log("ressss", response.data);
      setTipId(0);
      setTipName("");
      getTips();
      if (id == 0) {
        setSuccess(true);
        setSuccessMsg("Tip Added Successfully!");
      }
      else {
        setSuccess(true);
        setSuccessMsg("Tip Updated Successfully!");
      }

    }).catch(error => {
      console.log(error);
      getTips();
      setTipId(0);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }
  
  /*
    @function For ask for tip checkbox 
  */
  const handleAskForTipCheckbox = (e) => {
    setAskForTip(e);
    var tipLocDiv = document.getElementById("tip_entery_loc_div");
    var tipTable = document.getElementById("tipTable");
    if (e) {
      tipLocDiv.style.display = null;
      tipTable.style.display = null;

    } else {
      tipLocDiv.style.display = "none";
      tipTable.style.display = "none";

    }
  }


  /*
    @function For validate tip value
  */
  const validateTipValue = (e) => {
    var val = e.target.value;
    if (val.length < 6) {
      var per = val.replace(".", '');
      per = per / 100;
      if (!isNaN(per)) {
        setTipPer(parseFloat(per));
      }
    }
  }
  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Tip List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }


  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Tips List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Tips...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

        {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

        {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ padding: "10px" }}>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Tips</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>

          <CardBody>
          <GridContainer>

          <GridItem xs={12} sm={12} md={3} style={{"marginTop":"16px"}}>
          <FormControl component="fieldset">
              <FormLabel component="legend">Ask for tip&nbsp;&nbsp;&nbsp;&nbsp;</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={askForTip}
                    onChange={(e) => { handleAskForTipCheckbox(e.target.checked) }}
                    value="checkedA"
                  />
                }
                label="Yes"
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{"marginTop":"16px"}}>
          <FormControl component="fieldset" id="tip_entery_loc_div" style={{ display: 'none' }}>
              <FormLabel >Tip Entry Location</FormLabel>
              <RadioGroup value={tipEntryLocation} >
                <FormControlLabel onClick={(e) => { setTipEntryLocation(e.target.value) }} value="tab" control={<Radio />} label="on Tablet Screen" />
                <FormControlLabel onClick={(e) => { setTipEntryLocation(e.target.value) }} value="receipt" control={<Radio />} label="on Printed Receipt" />
              </RadioGroup>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{"marginTop":"16px"}}>
          <FormControl component="fieldset">
              <FormLabel >Tip Suggestion Calculation</FormLabel>
              <RadioGroup value={tipIncludeTaxInCalculation} >
                <FormControlLabel onClick={(e) => { setIncludeTaxInCalculation(1); console.log(tipIncludeTaxInCalculation); }} value={1} control={<Radio />} label="Include tax in calculation" />
                <FormControlLabel onClick={(e) => { setIncludeTaxInCalculation(0); console.log(tipIncludeTaxInCalculation); }} value={0} control={<Radio />} label="Don't include tax in calculation" />
              </RadioGroup>
            </FormControl>
          </GridItem>
          <GridItem id="gitipsavebtn" xs={12} sm={12} md={3} style={{"marginTop":"16px"}}>
          <FormControl component="fieldset" style={{width:'100%'}}>
              <FormLabel component="legend"></FormLabel>
              <FormControlLabel
                control={
                  <Button id="btnGreenAdd" variant="contained" style={{ backgroundColor: '#ffa726', color: '#FFFFFF',width:'100%' ,height:'60px'}} onClick={handaleSave} className={classes.button}>
                    Save
                  </Button>
                }
                label=""
              />
            </FormControl>
          </GridItem>
          </GridContainer>          

            <div style={{ clear: "both" }}></div>
            <div id='tipTable' style={{ display: "none" }}>
              <div style={{ marginTop: "5%" }}>
                <Button
                  id="btnGreenEdit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
                  onClick={() => { emptyAllField(); setTipPopup(true) }}
                >+</Button>
              </div>
              <Table
                tableHeaderColor="primary"
                tableHead={["Tip %", "Tip Label", "Edit", "Delete"]}
                tableData={tableData}
              />
            </div>
          </CardBody>
        </Card>
        {/* 
          * Add tip form start 
        */}
        <Dialog open={openAddTip} onClose={() => { setTipPopup(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(tipId == 0) ? "Add New " : "Update "}Tip</DialogTitle>
          <DialogContent>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Tip Name"
              onChange={(e) => setTipName(e.target.value)}
              value={tipName}
              fullWidth
            />
            <FormControl className={classes.formControl}>
            </FormControl>

            <GridContainer>
              <GridItem xs={10} sm={10} md={10}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="tipAmount"
                  label="Tip Percentage"
                  onChange={(e) => validateTipValue(e)}
                  value={tipAmount}
                  fullWidth
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
              <GridItem xs={2} sm={2} md={2} style={{ marginTop: "30px" }}>
                <font>%</font>
              </GridItem>
            </GridContainer>


          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTipId(0); setTipName(""); setTipPopup(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenEdit" onClick={() => { saveTip(tipId, tipName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(tipId == 0) ? "Add " : "Update"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          * Delete tip  popup start
        */}
        <Dialog
          open={tipDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete Tip?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTipId(0); setTipDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteTip} style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
