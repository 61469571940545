/*
  @page Item listing
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Helper from "./../../util/Helper";

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width:"100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight:{
    minHeight:"48px",
  },
};
function getStyles(category, categoryID, theme) {
  return {
    fontWeight:
      categoryID.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const useStyles = makeStyles(styles);

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function Item listing page logic
*/
var seletedCheckBox = {};
export default function TableList() {  
  const [tableData, setTableData] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [itemDeleteDailog, setItemDeleteDailog] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [multiCatShow, setMultiCatShow] = useState(false);
  const [formValidationError, setFormValidationError] = useState(true);
  const [openAddItem, setOpenAddItem] = React.useState(false);
  const [openEditItem, setOpenEditItem] = React.useState(false);
  const [itemName, setItemName] = React.useState("");
  const [itemColor, setColor] = React.useState("");
  const [pricingType, setPricingType] = React.useState("Fixed Price");
  const [unit, setUnit] = React.useState("");
  const [showInPOS, setShowInPOS] = React.useState(true);
  const [category, setcategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [multiSelectedCategory, setMultiSelectedCategory] = React.useState([]);
  const [modifire, setModifire] = React.useState([]);
  const [selectedModifire, setSelectedModifire] = React.useState([]);
  const [tax, setTaxName] = React.useState([]);
  const [selectedTaxes, setSelectedTaxes] = React.useState([]);
  const [sellPrice, setSellPrice] = React.useState("");
  const [costPrice, setCostPrice] = React.useState("");
  const [sku, setSKU] = React.useState("");
  const [posId, setPosId] = React.useState("");

  const [multiItemDeleteDailog, setMultiItemDeleteDailog] = useState(false);
  const [multiItemDuplicateDailog, setMultiItemDuplicateDailog] = useState(false);
  const [bulkActionDropdownValueZero, setBulkActionValueZero] = useState(false);
  const [accessPage, setAccessPage] = useState(1);
  


  /*
    @function For get categories,taxes and modifiers
  */
  const getCategoryList = () => {
    const data = {};
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('merchantToken') }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_add_form_detail', data, {
      headers: headers
    }).then(response => {
      const catName = new Object();
      const taxName = new Object();
      const modifireName = new Object();
      response.data.catData.forEach((eachCategory) => { //Fetch all category
        catName[eachCategory.id] = eachCategory.name;
      });

      response.data.taxesData.forEach((eachTax) => { //Fetch all taxes
        taxName[eachTax.id] = eachTax.name;
      });

      response.data.modifireData.forEach((eachModifire) => { //Fetch all modifiers
        modifireName[eachModifire.id] = eachModifire.name;
      });
      setcategory(catName);
      setTaxName(taxName);
      setModifire(modifireName);
    }).catch(error => {     
      return error;
    });
  }

  /*
    @function For set all values in edit dialog
  */
  const setEditVal = (editVal) => {
    setItemId(editVal.id);
    setItemName(editVal.name);
    setColor(editVal.color);
    setShowInPOS((editVal.show_in_pos == 1) ? true : false);
    setSelectedCategory(editVal.category_id.split(","));
    if (editVal.modifire_id !== '' && editVal.modifire_id !== null) {
      setSelectedModifire(editVal.modifire_id.split(","));
    }
    var sellprice =  Helper.viewCurrencyFormat(parseFloat(editVal.sell_price));
    setSellPrice(sellprice);
    var costprice =  Helper.viewCurrencyFormat(parseFloat(editVal.cost_price));
    setCostPrice(costprice);
    setSKU(editVal.sku);    
    if(editVal.taxes)
    {
      setSelectedTaxes(editVal.taxes.split(","));
    }    
    setPosId(editVal.pos_id);  
    setPricingType(editVal.price_type);
    setUnit(editVal.unit_name);
  }
  
  /*
    @function For clear all variables
  */
  const clearVal = () => {
    setItemId("");
    setItemName("");
    setColor("");
    setShowInPOS(true);
    setSelectedCategory([]);
    setSelectedModifire([]);
    setSellPrice("");
    setCostPrice("");
    setSKU("");
    setSelectedTaxes([]);
    setPosId("");
    setUnit("");
    setPricingType("Fixed Price");
  }
  
  /*
    @function For validation
  */
  const validateVal = () => {
    var errorMessageString = '';
    if (itemName === '' || itemName === null) //Check item name was null or blank
      errorMessageString += 'Please Enter Item Name';

    if (selectedCategory.toString() === '' || selectedCategory.toString() === null) //Check selected category was null or blank
      errorMessageString += '\nPlease Select Category';
    if (pricingType != 'Variable') { //Check pricing type was not variable
      if (sellPrice === '' || sellPrice === null) //Check sell price was null or blank
        errorMessageString += '\nPlease Enter Sell Price';
    }
   setErrorMsg(errorMessageString);
    if (errorMessageString === '') {
      setError(false);
      return true;
    }
    else {
      setError(true);
      return false;
    }

  }

  /*
    @function For get item list data
  */
  const getItemList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_item_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      const modArr = response.data.modifierData;
      const catArr = response.data.catData;

      response.data.itemData.forEach((eachItem) => {
        var catIds = [];
        if (eachItem.category_id !== '' && eachItem.category_id !== null) { //Check category id was blank or null
          catIds = eachItem.category_id.split(",");
        } else {
          catIds[0] = "";
        }

        var catNameString = ""; 
        if (catIds[0] !== '') { //Check category id was not blank
          catIds.forEach((eachcatId) => {
            catNameString = catNameString + catArr[eachcatId]["name"] + ",";
          });

        }

        if (catNameString !== "") { //Check category name string was not blank
          catNameString = catNameString.substring(0, catNameString.length - 1);
        }

        var modIds = [];
        if (eachItem.modifire_id !== '' && eachItem.modifire_id !== null) { //Check modifier id was not blank or null
          modIds = eachItem.modifire_id.split(",");
        } else {
          modIds[0] = "";
        }

        var modNameString = "";
        if (modIds[0] !== '') { //Check modifier id was not blank
          modIds.forEach((eachmodId) => {

            if (typeof modArr[eachmodId] !== 'undefined') {
              modNameString = modNameString + modArr[eachmodId]["name"] + ",";
            }

          });
        }

        if (modNameString !== "") { //Check modifier name string was not blank
          modNameString = modNameString.substring(0, modNameString.length - 1);
        }
        var sellprice = "";
        sellprice = Helper.viewCurrencyFormat(parseFloat(eachItem.sell_price));        
        userArr.push([<input type="checkbox" id={"row_"+eachItem.id} onChange={(e) => {(e.target.checked)?seletedCheckBox[eachItem.id] = eachItem.id:delete seletedCheckBox[eachItem.id];}} />,eachItem.id, eachItem.name, eachItem.color, (eachItem.show_in_pos == 1) ? <CheckIcon /> : <CloseIcon />, sellprice, modNameString, catNameString, eachItem.pos_id, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setEditVal(eachItem); setOpenEditItem(true); }}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setItemId(eachItem.id); setItemDeleteDailog(true); }} ><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      setTableData(userArr);

    }).catch(error => {     
      return error;
    });
  }

  /*
    @function For edit item
  */
  const editItem = () => {
    const data = {
      id: itemId,
      name: itemName,
      color: itemColor,
      show_in_pos: (showInPOS == true) ? 1 : 0,
      category_id: selectedCategory.toString(),
      modifire_id: selectedModifire.toString(),
      sell_price: sellPrice == '' ? 0 : sellPrice,
      cost_price: costPrice == '' ? 0 : costPrice,
      sku: sku,
      taxes: selectedTaxes.toString(),
      pos_id: posId,
      price_type: pricingType,
      unit_name: unit
    };
    setOpenEditItem(false);
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'update_item', data, {
      headers: headers
    }).then(response => 
      {
      clearVal();
      getItemList();      
      setSuccess(true);
      setSuccessMsg("Item Updated Successfully!");

    }).catch(error => {
      clearVal();
      getItemList();
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });
  }

  /*
    @function For delete item
  */
  const deleteItem = (itemID) => {
    setTableData(false);
    setItemDeleteDailog(false);
    const data = { id: itemID };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'delete_item', data, {
      headers: headers
    }).then(response => {
      getItemList();
      setItemId(0);
      if (response.data == 1) { //Check item was deleted or not
        setSuccess(true);
        setSuccessMsg("Item Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
      }


    }).catch(error => {
      getItemList();
      setItemId(0);
      setError(true);
      setErrorMsg("Some Error occure!!");
      return error;
    });

  }


  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setItemDeleteDailog(false);
    setError(false);
    setSuccess(false);
  };


  /*
    @function For handle multiselected item actions like delete or duplicate 
  */
  const multiselect_action = (action_name) =>
  {
  const data = { idArr: seletedCheckBox,operaton : action_name,category:multiSelectedCategory.toString()};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
  axios.post(window.apiUrl+'multiple_item_action', data, {
    headers: headers
  }).then(response => {
    getItemList();
    if(response.data == 1) 
    {
      setSuccess(true);
      if(action_name === "delete") //Check item was deleted or not
      {
        setSuccessMsg("Selected Items Deleted Successfully!");
      }else if(action_name === "duplicate") //Check item was duplicated or not
      {
        setSuccessMsg("Seleted Items Duplicated Successfully!");
      }else if(action_name === "category") //Check categories was updated on selected item or not
      {
        setSuccessMsg("Categories Updated Successfully On Seleted Items.");
      }
      
    } else {
      setError(true);
      setErrorMsg("Some Error Occure!!");
    }
  }).catch(error => {
    getItemList();
    setError(true);
    setErrorMsg("Some Error occure!!");
    return error;
  });
}
  
  /*
    @function For add new item
  */
const saveItem = () => {    
    if (validateVal() === true) { //Check if all value is proper or not
      const data = {
        name: itemName,
        color: itemColor,
        show_in_pos: (showInPOS == true) ? 1 : 0,
        category_id: selectedCategory.toString(),
        modifire_id: selectedModifire.toString(),
        sell_price: sellPrice == '' ? 0 : sellPrice,
        cost_price: costPrice == '' ? 0 : costPrice,
        sku: sku,
        taxes: selectedTaxes.toString(),
        pos_id: posId,
        price_type: pricingType,
        unit_name: unit
      };
      setOpenAddItem(false);
      setTableData(false);
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
      }
      data.parent_id = localStorage.getItem('parent_id');
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl+'add_item', data, {
        headers: headers
      }).then(response => {
        clearVal();
        getItemList();
        setSuccess(true);
        setSuccessMsg("Item Added Successfully!");

      }).catch(error => {
        getItemList();
        setError(true);
        setErrorMsg("Some Error Occure!!");
        return error;
      });
    }

  }

  const classes = useStyles();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  useEffect(() => {   

    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_item_catalog === 0)
      {
        setAccessPage(0);
        return;
      }
    }


    getCategoryList();
    getItemList();

  }, []);

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Item List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if data was not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Item List</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Item...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        
        {/* 
          Display error message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
          Display success message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Item List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
          <GridContainer>
          <GridItem xs={12} sm={12} md={6} >
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { setOpenAddItem(true) }}
            >+</Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl style={{width:"200px",float:"right"}}>
            <InputLabel id="demo-simple-select-label">Bulk Action</InputLabel>
            <Select
              style={{"float":"right"}}
              label="Bulk Action"
              value={bulkActionDropdownValueZero}
              onChange={(e) => { 
               if(e.target.value === "delete"){
                setMultiItemDeleteDailog(true)
                // multiselect_action("delete");
                }else if(e.target.value === "duplicate")
                {
                  // multiselect_action("duplicate");
                  setMultiItemDuplicateDailog(true)
                }
                else if(e.target.value === "category")
                {
                  setMultiCatShow(true);
                }
               }}
            >
              <option disabled selected value="0">Bulk Action</option>
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="delete">Delete</option>
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="duplicate">Duplicate</option>              
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="category">Category</option>              
            </Select>
            </FormControl>
            </GridItem>
            </GridContainer>

            {/* 
              *Display item listing
            */}
            <Table
              tableHeaderColor="primary"
              tableHead={["","ID", "Name", "Color", "Show In POS", "Amount", "Modifier Groups", "Categories", "POS ID", "Edit", "Delete"]}
              tableData={tableData}
            />
          </CardBody>
        </Card>

        {/* 
           * Delete Item  popup start
        */}
        <Dialog
          open={itemDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this item?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setItemId(0); setItemDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { deleteItem(itemId) }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={multiItemDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete selected items?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiItemDeleteDailog(false); setBulkActionValueZero(0); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { multiselect_action("delete");setMultiItemDeleteDailog(false); setBulkActionValueZero(0); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={multiItemDuplicateDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to duplicate selected items?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiItemDuplicateDailog(false); setBulkActionValueZero(0);}} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { multiselect_action("duplicate"); setMultiItemDuplicateDailog(false); setBulkActionValueZero(0); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* 
          *Multiple Item category change  popup start
        */}
        <Dialog
          open={multiCatShow}
          fullWidth={true}         
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Select Category"}</DialogTitle>
          <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={multiSelectedCategory}
                onChange={(e) => { setMultiSelectedCategory(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(multiSelectedCategory) => (
                  <div className={classes.chips}>
                    {
                      multiSelectedCategory.map((value) => (
                        <Chip key={value} label={category[value]} className={classes.chip} />
                      ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(category).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>

                  ))
                }
              </Select>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiCatShow(false)}} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={(e) => { multiselect_action("category"); setMultiCatShow(false); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Done
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          *Add Item form start 
        */}
        <Dialog open={openAddItem} onClose={() => { setOpenAddItem(false); }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New Item</DialogTitle>
          <DialogContent>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Item Name"
              onChange={(e) => setItemName(e.target.value)}
              value={itemName}
              fullWidth
            />
            <FormControlLabel xs={6} sm={6} md={6}
              control={
                <Checkbox
                  checked={showInPOS}
                  onChange={(e) => setShowInPOS(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Show In POS"
            />
            <div style={{ clear: 'both' }}></div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                className={classes.setFieldHeight}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={itemColor}
                onChange={(e) => setColor(e.target.value)}
              >
                <MenuItem value={"Black"}>Black</MenuItem>
                <MenuItem value={"Blue"}>Blue</MenuItem>
                <MenuItem value={"Gray"}>Gray</MenuItem>
                <MenuItem value={"Green"}>Green</MenuItem>
                <MenuItem value={"Orange"}>Orange</MenuItem>
                <MenuItem value={"Red"}>Red</MenuItem>
              </Select>
            </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth}>
              <InputLabel id="demo-mutiple-chip-label">Category</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedCategory}
                onChange={(e) => { setSelectedCategory(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedCategory) => (
                  <div className={classes.chips}>
                    {
                      selectedCategory.map((value) => (
                        <Chip key={value} label={category[value]} className={classes.chip} />
                      ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(category).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>

                  ))
                }
              </Select>
            </FormControl>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} >
              <InputLabel id="demo-mutiple-chip-label">Modifier Groups</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedModifire}
                onChange={(e) => { setSelectedModifire(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedModifire) => (
                  <div className={classes.chips}>
                    {
                      selectedModifire.map((value) => (
                        <Chip key={value} label={modifire[value]} className={classes.chip} />
                      ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(modifire).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>

                  ))
                }
              </Select>
            </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} >
              <InputLabel id="demo-mutiple-chip-label">Taxes</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedTaxes}
                onChange={(e) => { setSelectedTaxes(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedTaxes) => (
                  <div className={classes.chips}>
                    {selectedTaxes.map((value) => (
                      <Chip key={value} label={tax[value]} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(tax).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} style={{marginTop:10}}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Pricing Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pricingType}
                onChange={(e) => {setPricingType(e.target.value);setSellPrice("");setCostPrice("")}}
              >
                <MenuItem value={"Fixed Price"}>Fixed Price</MenuItem>
                <MenuItem value={"Variable"}>Variable</MenuItem>
                <MenuItem value={"Per Unit"}>Per Unit</MenuItem>

              </Select>
            </FormControl>
            </GridItem>
            </GridContainer>


            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="sellPrice"
                  label="Sell Price"
                  onChange={(e) => { setSellPrice(Helper.currencyFormatWithDot(e, 'sellPrice')) }}
                  value={sellPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '' }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>  
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="costPrice"
                  label="Cost Price"
                  onChange={(e) => setCostPrice(Helper.currencyFormatWithDot(e, 'costPrice'))}
                  value={costPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '' }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
           </GridContainer>


            <TextField
              autoFocus
              margin="dense"
              id="unit"
              label="Enter Unit"
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              fullWidth
              style={{ display: (pricingType == 'Per Unit') ? "" : "none" }}
            />

            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="sku"
                  label="SKU"
                  onChange={(e) => setSKU(e.target.value)}
                  value={sku}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="posId"
                  label="POS ID"
                  onChange={(e) => setPosId(e.target.value)}
                  value={posId}
                  fullWidth
                />
              </GridItem>
            </GridContainer>


          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenAddItem(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveItem() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/*
          *Edit Item form start 
         */}
        <Dialog open={openEditItem} onClose={() => { setOpenEditItem(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>
          <DialogContent>
           
            
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Item Name"
              onChange={(e) => setItemName(e.target.value)}
              value={itemName}
              fullWidth
            />
           
            <FormControlLabel
              margin="dense"
              control={
                <Checkbox
                  checked={showInPOS}
                  onChange={(e) => setShowInPOS(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Show In POS"
            />
            <div style={{ clear: 'both' }}></div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} xs={12} sm={12} md={6}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className={classes.setFieldHeight}
                id="demo-simple-select"
                value={itemColor}
                onChange={(e) => setColor(e.target.value)}
              >
                <MenuItem value={"Black"}>Black</MenuItem>
                <MenuItem value={"Blue"}>Blue</MenuItem>
                <MenuItem value={"Gray"}>Gray</MenuItem>
                <MenuItem value={"Green"}>Green</MenuItem>
                <MenuItem value={"Orange"}>Orange</MenuItem>
                <MenuItem value={"Red"}>Red</MenuItem>

              </Select>
            </FormControl>
             </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} xs={12} sm={12} md={6}>
              <InputLabel id="demo-mutiple-chip-label">Category</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedCategory}
                onChange={(e) => { setSelectedCategory(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedCategory) => (
                  <div className={classes.chips}>
                    {
                      selectedCategory.map((value) => (
                        <Chip key={value} label={category[value]} className={classes.chip} />
                      ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(category).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>

                  ))
                }
              </Select>
            </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} xs={6} sm={6} md={6}>
              <InputLabel id="demo-mutiple-chip-label">Modifier Groups</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedModifire}
                onChange={(e) => { setSelectedModifire(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedModifire) => (
                  <div className={classes.chips}>
                    {
                      selectedModifire.map((value) => (
                        <Chip key={value} label={modifire[value]} className={classes.chip} />
                      ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(modifire).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>

                  ))
                }
              </Select>
            </FormControl>
             </GridItem>
             
             <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.popupHalfWidth} xs={6} sm={6} md={6}>
              <InputLabel id="demo-mutiple-chip-label">Taxes</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                className={classes.setFieldHeight}
                id="demo-mutiple-chip"
                multiple
                value={selectedTaxes}
                onChange={(e) => { setSelectedTaxes(e.target.value) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selectedTaxes) => (
                  <div className={classes.chips}>
                    {selectedTaxes.map((value) => (
                      <Chip key={value} label={tax[value]} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  Object.entries(tax).map(([key, value]) => (
                    <MenuItem key={key} value={key} >
                      {value}
                    </MenuItem>
                  ))
                }

              </Select>
            </FormControl>
             </GridItem>

            <div style={{ clear: 'both' }}></div>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.formControl} xs={6} sm={6} md={6}>
              <InputLabel id="demo-simple-select-label">Pricing Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pricingType}
                onChange={(e) => {setPricingType(e.target.value);setSellPrice("");setCostPrice("")}}
              >
                <MenuItem value={"Fixed Price"}>Fixed Price</MenuItem>
                <MenuItem value={"Variable"}>Variable</MenuItem>
                <MenuItem value={"Per Unit"}>Per Unit</MenuItem>

              </Select>
            </FormControl>
            </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="sellPrice"
                  label="Sell Price"
                  onChange={(e) => setSellPrice(Helper.currencyFormatWithDot(e, 'sellPrice'))}
                  value={sellPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '' }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="costPrice"
                  label="Cost Price"
                  onChange={(e) => setCostPrice(Helper.currencyFormatWithDot(e, 'costPrice'))}
                  value={costPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '' }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
            </GridContainer>

            <TextField
              autoFocus
              margin="dense"
              id="unit"
              label="Enter Unit"
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              fullWidth
              style={{ display: (pricingType == 'Per Unit') ? "" : "none" }}
            />
            
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="sku"
             label="SKU"
              onChange={(e) => setSKU(e.target.value)}
              value={sku}
              fullWidth
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="posId"
              label="POS ID"
              onChange={(e) => setPosId(e.target.value)}
              value={posId}
              fullWidth
            />
            </GridItem>
            </GridContainer>
          
          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenEditItem(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { editItem() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
