/*
   @page user profile
*/

//Import require libraries
import React, { useEffect, useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch } from "@material-ui/core";
import Helper from "./../../util/Helper";

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  hiddenContent: { display: 'none', }
};

const useStyles = makeStyles(styles);

/*
  @const State array
*/
const actions = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North", value: "North" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon	Salem", value: "Oregon	Salem" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "US Virgin Islands", value: "US Virgin Islands" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wiscons", value: "Wiscons" },
  { label: "Wyoming", value: "Wyoming" },
];

/*
  @function user profile page logic
*/
export default function UserProfile() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passcode, setPasscode] = useState("");
  const [token, setToken] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [createMerchantSuccessDailog, setCreateMerchantSuccessDailog] = useState(false);
  const [shouldHideLoading, setShouldHideLoading] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(true);
  const [website, setWebsite] = useState("");
  const [sendIvuData, setSendIvuData] = useState(0);
  const [genrateIvu, setGenrateIvu] = useState(0);
  const [emailArr, setEmailArr] = useState(0);

   useEffect(() => {
      getAllMerchant();
  }, []);

  function getAllMerchant()
  {

    const data = {   
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'get_all_merchant', data, {
      headers: headers
    }).then(response => {
      const emails = [];
         response.data.forEach((eachMerchant) => {
          emails[eachMerchant.email] = eachMerchant.name;
         });
         setEmailArr(emails);
    }).catch(error => {
      
      return error;
    });

  }
  /*
    * Function validate web url
  */
  function validURL(str) {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(str);                  
  }

  /*
    * Function for handle add merchant form submit
  */
  function handleSubmit(event) {
    event.preventDefault(); 
    if(!validURL(String(website))&& website != "" && website != null) //Check if web url is not propper or not null or not blank
    {
      setError(true);
      setErrorMsg("Please Enter Valid URL");    
      return false;
    }
    if(passcode.length < 4)
    {
      setError(true);
      setErrorMsg("passcode must be 4 digit long");
      return false;
    }
    setCreateMerchantSuccessDailog(false);
    setShouldHideLoading(true);
    setSubmitBtn(false);
       

    const data = {
      name: contactName,
      email: email,
      password: password,
      passcode: passcode,
      business_name: businessName,
      street_address: streetAddress,
      city: city,
      state: state,
      zip: zip,
      phone_number: phoneNumber,
      puerto_rico_number: taxIdNumber,
      website:website,
      merchant_id:merchant_id,
      send_ivu_data:sendIvuData,
      evertec_flag:genrateIvu,
      merchant_cat_code:merchant_cat_code
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'create_user', data, {
      headers: headers
    }).then(response => {
      
          //response.data.token  // Check if token is generated or not
          setCreateMerchantSuccessDailog(true);
            setTimeout(() => {
              window.location.href = "table";  
            }, 4000);  
        
      
         

    }).catch(error => {
      if (error.response.data.error.email) {
        setErrorMsg(error.response.data.error.email);            
      }
     else if (error.response.data.error.password) {
        setErrorMsg(error.response.data.error.password);             
      }
      setError(true);      
      setShouldHideLoading(false);
      setSubmitBtn(true);      
      return error;
    });

  }
    
  /*
    * Function handle merchant listing
  */
  const handleMerchantListing = () => {
    window.location.href = "table";
  }

  /*
    * Const custom style
  */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
    }),
    control: (provided) => ({ 
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)"
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#80ADFF"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1769ff"
      }
    }),
  }

  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [merchant_id, setMerchantId] = useState("");
  const [merchant_cat_code, setMerchantCatCode] = useState("");
  const [hidePass, setHidePass] = useState({});

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  return (
    <div>
      {/* 
        Display error message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={Error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* 
              * Display add merchant form
            */}
            <form onSubmit={handleSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Add Merchant</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant ID"
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{maxLength: 24}}
                      
                      value={merchant_id}
                      onChange={(e) => setMerchantId(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant Category Code"
                      id="m_cat_code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{max: 4,type:'number'}}                      
                      value={merchant_cat_code}                      
                      onChange={(e) => (e.target.value.length>4)?zip:setMerchantCatCode(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Business Name"
                      id="businessname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Street Address"
                      id="streetaddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={streetAddress}
                      onChange={(e) => setStreetAddress(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer styles={{ marginTop: 50 }}>
                  <GridItem xs={12} sm={12} md={6}>


                    <Select
                      value={actions.filter(function (action) {
                        return action.value === state;
                      })}
                      onChange={(e) => setState(e.value)} options={actions}
                      styles={customStyles}
                    />

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Zip Code"
                      inputProps={{max: 5,type:'number'}}
                      id="zipcode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={zip}
                      onChange={(e) => (e.target.value.length>5)?zip:setZip(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contact Name"
                      id="contactname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone Number"
                      id="phonenumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                     // value={phoneNumber}
                      value={Helper.formatPhoneNumber(phoneNumber)}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Email Address"
                      id="emailaddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={email}
                      onChange={(e) => {setEmail(e.target.value);(emailArr[e.target.value])?setHidePass({display:"none"}):setHidePass({})}}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  
                      <GridItem xs={12} sm={12} md={4} style={{display:(state === "Puerto Rico")?'block':'none'}}>
                        <CustomInput
                          labelText="Puerto Rico Tax ID Number"
                          id="taxidnumber"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={taxIdNumber}
                          onChange={(e) => setTaxIdNumber(e.target.value)}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4} style={{display:(state === "Puerto Rico")?'block':'none'}}>
                        <div style={{marginTop:'10%',marginLeft:'5%'}}>
                        Do you want to generate IVU Control Number?
                          <Switch  
                            checked={genrateIvu}
                            onChange={(e)=>{setGenrateIvu(e.target.checked)}}   
                            id="genIvuSwitch"               
                          />
                          </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4} style={{display:(state === "Puerto Rico" && genrateIvu)?'block':'none'}}>
                        <div style={{marginTop:'10%',marginLeft:'5%'}}>
                          Send IVU Data?
                          <Switch  
                            checked={sendIvuData}
                            onChange={(e)=>{setSendIvuData(e.target.checked)}}   
                            id="sendIvuSwitch"               
                          />
                          </div>
                      </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Website"
                      id="web"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={4} style={hidePass}>                    
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />                    
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                      labelText="Passcode"
                      inputProps={{ max: 4, type: 'number' }}
                      id="passcode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={String(passcode)}                      
                      onChange={(e) => (e.target.value.length > 4) ? passcode : setPasscode(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning" className={submitBtn ? '' : classes.hiddenContent} style={{ float: 'left' }}>Create Merchant</Button>
                <CircularProgress id="successProgress" className={shouldHideLoading ? '' : classes.hiddenContent} style={{ float: 'left' }}></CircularProgress>
              </CardFooter>
            </form>
          </Card>

          {/* Merchant Success  popup start*/}
          <Dialog
            open={createMerchantSuccessDailog}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >

            <DialogTitle id="alert-dialog-slide-title">
              <div style={{ textAlign: 'center' }}><CheckCircleIcon style={{ color: '#008000', fontSize: '40px' }}></CheckCircleIcon></div>
              <div style={{ textAlign: 'center' }}>{"Merchant has been successfully created."}</div>
              <div style={{ textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>{"Please wait, Redirecting to merchant listing...."}</div>
            </DialogTitle>
            <DialogActions>


              {/*
            <Button onClick={handleMerchantListing} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Go to list
            </Button>
            */}

            </DialogActions>
          </Dialog>
          {/* Merchant Success  popup  end*/}

        </GridItem>

      </GridContainer>
    </div>
  );
}
