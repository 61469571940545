/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Merchant from "layouts/Merchant.js";
import Login from "layouts/Login.js";
import TransactionErrorCheck from "merchantViews/TransactionErrorCheck/TransactionErrorCheck.js";
import ForgotPassword from "merchantViews/ForgotPassword/ForgotPassword.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/media-query.css"
// window.apiUrl = "https://pax.magicpayivu.com/api/";
window.apiUrl = "https://api.magicpaypos.com/api/";
if(localStorage.getItem('parent_id') == 49)
{
  window.apiUrl = "https://api.magicpaypos.com/api/";
   
}
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/transactionErrCheck" component={TransactionErrorCheck} />
      <Route path="/login" component={Login} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/admin" component={Admin} />
      {/*<Route path="/rtl" component={RTL} />*/}
      <Route path="/" component={Merchant} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
