/*
  @page Device manager
*/

//Import require libraries
import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@mui/material/Pagination';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RemoveRedEyeRoundedIcon from '@material-ui/icons/RemoveRedEyeRounded';
import Helper from "./../../util/Helper";
import { jsPDF } from "jspdf";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { CSVLink, CSVDownload } from 'react-csv';

/*
  @const Page styles
*/
const styles = {
  root: {
    '& .Mui-selected': {
      backgroundColor: 'black',
      color: '#ffa726',
    }
  },
  rowPerPage:
  {
    minHeight: "40px",
    height: "40px"
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726"
    }
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    width: '93%',
    height: '45px',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  datepicker: {
    width: '100%',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function Transaction list page logic
*/
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [openSendReceipt, setOpenSendReceipt] = useState(false);
  const [openSMSReceipt, setOpenSMSReceipt] = useState(false);
  const [sendNumber, setSendNumber] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [ecrRefNo, setEcrRefNo] = useState(0);
  const [amt, setAmt] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [filterDailogOpen, setFilterDailogOpen] = useState(false);
  const [filterAmtFrom, setFilterAmtFrom] = useState("");
  const [filterAmtTo, setFilterAmtTo] = useState("");
  const [filterTenderType, setFilterTenderType] = useState([]);
  const [filtercardType, setFilterCardType] = useState([]);
  const [filterEmp, setFilterEmp] = useState([]);
  const [filterTransactionType, setFilterTransactionType] = useState([]);
  const [filterPaymentRes, setFilterPaymentRes] = useState([]);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPages, setCurrentPages] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [exportButtonLabel, setExportButtonLabel] = useState("Export");
  const [settingData, setSettingData] = useState("");
  const [empIdMap, setEmpIdMap] = useState({});
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [selectedEmpName, setSelectedEmpName] = useState("");
  const [accessPage, setAccessPage] = useState(1);  

  const addclass=()=>{
    document.querySelector(".ps--active-y").classList.remove('newTop');
    document.querySelector(".ps--active-y").classList.add('newTop');
    setTimeout(function() {
      document.querySelector(".ps--active-y").classList.remove('newTop');
    }, 2000);
  }  

  useEffect(() => {
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
       
       if(permissonData)
       {
         if(permissonData.access_transaction_history === 0)
         {
           setAccessPage(0);
           return;
         }
       }

    const data = { 'current_page': currentPages, 'rowPerPage': rowPerPage };
    getTransList(data);
    getReportFilterData();
    getSettingData();
  }, []);


  /*
    @function get setting data from api
  */
  const getSettingData = () => {
    const dataSetting = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    dataSetting.parent_id = localStorage.getItem('parent_id');
    dataSetting.location_id = localStorage.getItem('location_id');    
    axios.post(window.apiUrl + 'get_setting', dataSetting, {
      headers: headers
    }).then(response => {
      setSettingData(response.data);
    });

  }

  /*
    @function get filtered data using employee name and device
  */
  const getReportFilterData = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_report_filter_data', data, {
      headers: headers
    }).then(response => {
      setEmpDropDownVal(response.data.emp);
      setDevicesDropDownVal(response.data.devices);
    }).catch(error => {
      return error;
    });

  }

  /*
    @function get transaction list
  */
  const getTransList = (body) => {
    const dataSetting = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    var settingData = {};
    dataSetting.parent_id = localStorage.getItem('parent_id');
    dataSetting.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_setting', dataSetting, {
      headers: headers
    }).then(response => {
      settingData = response.data;
    });
    const data = body;
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_transaction', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      const exportArr = [];
      console.log("******");
      console.log(response);
      setTotalPages(response.data.totalPage);
        setCurrentPages(response.data.current_page);
      response.data.transData.forEach((eachTransaction) => {
        var eachAmt = "";
        eachAmt = Helper.viewCurrencyFormat(parseFloat(eachTransaction.amount));
        var t = new Date(eachTransaction.device_created_at);
       
        
        const recLink = window.apiUrl.replace("/api/", "/mail_receipt/") + eachTransaction.ecrref_num;

        if (eachTransaction.response_code !== "000000") { //Check transaction was declined
          var transStatusIcon = <CancelIcon style={{ color: 'red' }}></CancelIcon>;
          var transStatusExport = "Declined";

        } else if (eachTransaction.transaction_type === "RETURN") { //Check transaction was return
          var transStatusIcon = <RemoveCircleOutlineIcon style={{ color: 'red' }}></RemoveCircleOutlineIcon>;
          var transStatusExport = "Return";
        } else { //else transaction success
          var transStatusIcon = <CheckCircleIcon style={{ color: 'green' }}></CheckCircleIcon>;
          var transStatusExport = "Success";
        }
        userArr.push([eachTransaction.id, transStatusIcon, eachTransaction.payment_method, eachAmt, (eachTransaction.is_void === 1) ? "VOID" : "SALE", <a target="_blank" href={recLink}><RemoveRedEyeRoundedIcon style={{ color: '#ffa726' }}></RemoveRedEyeRoundedIcon></a>, <div style={{display:'flex'}}><Button variant="contained" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', textTransform: 'none' }} color="primary" className={classes.button} onClick={() => { setEcrRefNo(eachTransaction.ecrref_num); setAmt(eachTransaction.amount); setOpenSendReceipt(true) }} >Email</Button><Button variant="contained" style={{ marginLeft: '5px', backgroundColor: '#ffa726', color: '#FFFFFF', textTransform: 'none' }} color="primary" className={classes.button} onClick={() => { setEcrRefNo(eachTransaction.ecrref_num); setOpenSMSReceipt(true) }} >SMS</Button><Button style={{ marginLeft: '5px', backgroundColor: '#ffa726', color: '#FFFFFF', textTransform: 'none' }} onClick={() => { downloadPdf(eachTransaction.ecrref_num); }} ><PictureAsPdfIcon /></Button> </div>, parseInt(t.getMonth() + 1) + "/" + t.getDate() + "/" + t.getFullYear(), t.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })]);
      });
      setTableData(userArr); // Set data inside table
    }).catch(error => {
      return error;
    });
  }

  /*
    @function Sent receipt in email
  */
  const sendEmailReceipt = () => {
    const data = {
      "receipt_email_address": sendEmail,
      "ecr_ref_no": ecrRefNo,
      "total_amount": amt,
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'send_receipt_email', data, {
      headers: headers
    }).then(response => {
      setOpenSendReceipt(false);
      setSendEmail("");
      setEcrRefNo(0);
      setAmt(0);
      if (response.data.msg === "success") { //Check receipt was sent in email successfully
        setSuccess(true);
        setSuccessMsg("Receipt Sent Successfully!");
      }
      else {
        setError(true);
        setErrorMsg("Some Error Occure,Receipt Not Sent!!");
      }
    }).catch(error => {
      return error;
    });

  }
  
  /*
    @function for search transaction list
  */
  const handleSearch = () => {
    setTableData(false);
    var sDate = new Date(startDate);    
    if (endDate === null || endDate === "" || endDate === undefined) {
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }    
    const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'current_page': currentPages, 'rowPerPage': rowPerPage, 'filterAmtFrom': filterAmtFrom, 'filterAmtTo': filterAmtTo, 'filterTenderType': filterTenderType, 'filtercardType': filtercardType, 'filterEmp': filterEmp, 'filterDevice': filterDevice, 'filterTransactionType': filterTransactionType, 'filterPaymentRes': filterPaymentRes };
    getTransList(data);
  }

  
  /*
    @function Download receipt in pdf format
  */
  const downloadPdf = (ecrNum) => {
    const data = {
      "ECRRefNum": ecrNum,
      "IsWebPDF": "1",
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.get(window.apiUrl + 'mail_receipt/' + ecrNum+ '?IsWebPDF=1', data, {
      headers: headers
    }).then(response => {
      let htmlStr = response.data;

      htmlStr = htmlStr.replace('<body style="background-color:white;">', '<body style="background-color:white;"><div style="">');
      htmlStr = htmlStr.replace('</body>', '</div></body>');  
      
      let doc = new jsPDF("portrait", 'pt', 'a4');
      doc.html(htmlStr, {
        callback: () => {
          doc.save('receipt_' + ecrNum + '.pdf');
        }
      });
      setEcrRefNo(0);
    }).catch(error => {
      setError(true);
      setErrorMsg("Fail to download");
      return error;
    });
  }

  /*
    @function Sent receipt in sms
  */
  const sendSMSReceipt = () => {

    const data = {
      "number": sendNumber,
      "ecrRefNum": ecrRefNo,

    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'sms', data, {
      headers: headers
    }).then(response => {
      setOpenSMSReceipt(false);
      setSendNumber("");
      setEcrRefNo(0);
      setSuccess(true);
      setSuccessMsg("SMS Send Successfully!");
    }).catch(error => {

      setError(true);
      setErrorMsg(error.response);      
      return error;
    });

  }
  
  /*
    @function for handle how many record display in single page
  */
  const handelPagination = (e, v, rows) => {
    setTableData(false);
    if (startDate) {
      var sDate = new Date(startDate);
      if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
      } else {
        var eDate = new Date(endDate);
      }
      const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'current_page': v, 'rowPerPage': rows, 'filterAmtFrom': filterAmtFrom, 'filterAmtTo': filterAmtTo, 'filterTenderType': filterTenderType, 'filtercardType': filtercardType, 'filterEmp': filterEmp, 'filterDevice': filterDevice, 'filterTransactionType': filterTransactionType, 'filterPaymentRes': filterPaymentRes };
      getTransList(data);
    } else {
      const data = { 'current_page': v, 'rowPerPage': rows };
      getTransList(data);
    }

  } 

  /*
    @function for select multiple devices on filter
  */
    const [filterDevice, setFilterDevice] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterDevice(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function for select multiple tender types on filter
  */
  const handleTenderTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTenderType(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function for select multiple Employees on filter
  */
  const handleEmpChange = (event) => {
    const {
      target: { value },
    } = event;
    const idArr = event.target.value;
    const nameArr = [];
    idArr.map((eachId) => {       
    });
    var getSelectedEmpName = "";
    empDropDownVal.map((eachEmp) => {
      if (idArr.indexOf(eachEmp.user_id) > -1) {
        if (getSelectedEmpName == "") {
          getSelectedEmpName = eachEmp.employee_name;
        } else {
          getSelectedEmpName = getSelectedEmpName + " , " + eachEmp.employee_name;
        }
        setSelectedEmpName(getSelectedEmpName);
      }
    })
    setFilterEmp(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function for select multiple card types on filter
  */
  const handleCardTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterCardType(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function for select multiple transaction types on filter
  */
  const handleTransTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTransactionType(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function for select multiple payment results on filter
  */
  const handlePaymentResChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterPaymentRes(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /*
    @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function for generate and download csv file
  */
  const csvLink = useRef();
    const handelExport = () => {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    var sDate = new Date(startDate);
    
    if (endDate === null || endDate === "" || endDate === undefined) { //Check enddate was null,blank or undefined
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }
    
    const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'filterAmtFrom': filterAmtFrom, 'filterAmtTo': filterAmtTo, 'filterTenderType': filterTenderType, 'filtercardType': filtercardType, 'filterEmp': filterEmp, 'filterDevice': filterDevice, 'filterTransactionType': filterTransactionType, 'filterPaymentRes': filterPaymentRes };
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_export_transaction', data, {
      headers: headers
    }).then(response => {

      const deviceNickNameArr = [];  
      response.data.device_data.forEach((eachDevice) => {
        deviceNickNameArr[eachDevice.serial_number] = eachDevice.nick_name;
      });
      const exportArr = [];
      exportArr.push(["Date", "ECR#", "Employee", "Status", "Tender Type", "Transaction Amount", "Tip Amount", "Total Amount", "Card Type", "Transaction Type", "Card Number", "Card Holder Name", "Device","Device Nick Name","Customer Name","Table Number"]);
      response.data.transData.forEach((eachTransaction) => {
        var eachAmt = "";
        eachAmt = Helper.viewCurrencyFormat(parseFloat(eachTransaction.amount));
        var t = new Date(eachTransaction.device_created_at);

        if (eachTransaction.response_code !== "000000") { //Check transaction response
          var transStatusExport = "Declined";
        } else if (eachTransaction.transaction_type === "RETURN") { //Check transaction type was return
          var transStatusExport = "Return";
        } else {
          var transStatusExport = "Success";
        }
        var transAmt = Helper.viewCurrencyFormat(parseFloat(eachTransaction.amount - eachTransaction.tip_amt));
        var marchantName = '';
        if (eachTransaction.emp_id != 0) { //Check if employee id was not 0
          empDropDownVal.map((eachEmp) => {
            if (eachTransaction.emp_id == eachEmp.user_id) {
              marchantName = eachEmp.employee_name;
            }
          });
        } else {
          if (settingData.settingData.user_id != undefined && eachTransaction.user_id === settingData.settingData.user_id) {
            marchantName = settingData.settingData.name;
          }
        }
        exportArr.push([eachTransaction.device_created_at, eachTransaction.ecrref_num, marchantName, transStatusExport, eachTransaction.payment_method, transAmt, Helper.viewCurrencyFormat(parseFloat(eachTransaction.tip_amt)), eachAmt, eachTransaction.card_type, (eachTransaction.is_void === 1) ? "VOID" : "SALE", eachTransaction.card_number, eachTransaction.card_holder_name, "SN:" + eachTransaction.serial_number,(deviceNickNameArr[eachTransaction.serial_number])?deviceNickNameArr[eachTransaction.serial_number]:"",eachTransaction.customer_name,eachTransaction.table_number]);

      });
      setCsvData(exportArr);
      csvLink.current.link.click();
      setExportButtonLabel("Export");
    }).catch(error => {
      return error;
    });
  }

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Transaction List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Transaction List</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Transaction...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);
  }
  
  return (
    <GridContainer>
      {
        (csvData) ? <CSVLink data={csvData} ref={csvLink} target='_blank'></CSVLink> : ""
      }
      {/* Filter Popup start */}
      <Dialog
        open={filterDailogOpen}
        keepMounted
        onClose={() => { }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >

        <DialogTitle id="alert-dialog-slide-title">{"More Filters"}</DialogTitle>

        <DialogActions>
          <Button onClick={() => { }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button onClick={() => { }} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      {/* Filter Popup end  */}

      
      {/* 
        Display error message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      {/* Send email receipt start */}
      <Dialog open={openSendReceipt} onClose={() => { setOpenSendReceipt(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Email Receipt</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Email Address"
            type="email"
            onChange={(e) => setSendEmail(e.target.value)}
            value={sendEmail}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setEcrRefNo(0); setSendEmail(""); setOpenSendReceipt(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button id="btnGreenEdit" onClick={() => { sendEmailReceipt() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send email receipt end */}


      {/* Send SMS receipt start */}
      <Dialog open={openSMSReceipt} onClose={() => { setOpenSMSReceipt(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="25px"
            id="name"
            label="Enter Mobile Number"
            type="text"
            onChange={(e) => setSendNumber(e.target.value)}
            value={Helper.formatPhoneNumber(sendNumber)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button id="btnGreyCancel"  onClick={() => { setEcrRefNo(0); setSendNumber(""); setOpenSMSReceipt(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button id="btnGreenAdd"  onClick={() => { sendSMSReceipt() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send SMS receipt end */}

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Transaction List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {/* Select start date and end date */}
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <span>Start Date</span>
                  <DatePicker className={classes.datePickerTextBox} selected={startDate} onChange={(date) => setStartDate(date)} />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <span>End Date</span>
                  <DatePicker className={classes.datePickerTextBox} selected={endDate} onChange={(date) => setEndDate(date)} />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Ammount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    autoFocus
                    margin="full"
                    label="Amount From"
                    onChange={(e) => { setFilterAmtFrom(e.target.value) }}
                    value={filterAmtFrom}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} >
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Ammount to textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    autoFocus
                    label="Amount To"
                    onChange={(e) => { setFilterAmtTo(e.target.value) }}
                    value={filterAmtTo}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <div style={{ height: "25px" }}>&nbsp;</div>
            <GridContainer>

              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select tender types */}
                  <InputLabel id="demo-simple-select-helper-label">Tender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '100%' }}
                    multiple
                    value={filterTenderType}
                    onChange={handleTenderTypeChange}
                    input={<OutlinedInput label="Tender" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Cash" value="Cash">
                      <Checkbox checked={filterTenderType.indexOf("Cash") > -1} />
                      Cash
                    </MenuItem>

                    <MenuItem key="Check" value="Check">
                      <Checkbox checked={filterTenderType.indexOf("Check") > -1} />
                      Check
                    </MenuItem>

                    <MenuItem key="Debit" value="Debit">
                      <Checkbox checked={filterTenderType.indexOf("Debit") > -1} />
                      Debit
                    </MenuItem>

                    <MenuItem key="Credit" value="Credit">
                      <Checkbox checked={filterTenderType.indexOf("Credit") > -1} />
                      Credit
                    </MenuItem>

                    <MenuItem key="EBT" value="EBT">
                      <Checkbox checked={filterTenderType.indexOf("EBT") > -1} />
                      EBT
                    </MenuItem>

                  </Select>
                </FormControl>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select card types */}
                  <InputLabel id="demo-simple-select-helper-label">Card Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '100%' }}
                    multiple
                    value={filtercardType}
                    onChange={handleCardTypeChange}
                    input={<OutlinedInput label="Card Type" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >

                    <MenuItem key="Visa" value="Visa">
                      <Checkbox checked={filtercardType.indexOf("Visa") > -1} />
                      Visa
                    </MenuItem>

                    <MenuItem key="MasterCard" value="MasterCard">
                      <Checkbox checked={filtercardType.indexOf("MasterCard") > -1} />
                      Master Card
                    </MenuItem>

                    <MenuItem key="Discover" value="Discover">
                      <Checkbox checked={filtercardType.indexOf("Discover") > -1} />
                      Discover
                    </MenuItem>

                    <MenuItem key="AMEX" value="AMEX">
                      <Checkbox checked={filtercardType.indexOf("AMEX") > -1} />
                      American Express
                    </MenuItem>

                    <MenuItem key="EBT" value="EBT">
                      <Checkbox checked={filtercardType.indexOf("EBT") > -1} />
                      EBT
                    </MenuItem>

                    <MenuItem key="Other" value="Other">
                      <Checkbox checked={filtercardType.indexOf("Other") > -1} />
                      Other
                    </MenuItem>

                  </Select>
                </FormControl>

              </GridItem>

              <GridItem xs={12} sm={12} md={3}>

                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select employees */}
                  <InputLabel id="demo-simple-select-helper-label">Employee</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '100%' }}
                    multiple
                    value={filterEmp}
                    onChange={handleEmpChange}
                    input={<OutlinedInput label="Employee" />}
                    renderValue={value => selectedEmpName}
                    MenuProps={MenuProps}
                  >
                    {
                      empDropDownVal.map((eachEmp) => {
                        console.log("emoiddd"+eachEmp.user_id);
                        empIdMap[eachEmp.user_id] = eachEmp.employee_name;
                        return <MenuItem key={eachEmp.employee_name} value={eachEmp.user_id}>
                          <Checkbox checked={filterEmp.indexOf(eachEmp.user_id) > -1} />
                          {eachEmp.employee_name}
                        </MenuItem>;
                      })}
                  </Select>
                </FormControl>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select devices */}
                  <InputLabel id="demo-simple-select-helper-label">Device</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '100%' }}
                    multiple
                    value={filterDevice}
                    onChange={handleChange}
                    input={<OutlinedInput label="Device" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {devicesDropDownVal.map((eachDevice) => {
                      var x = (parseInt(eachDevice.is_approve) === 0) ? <i>&nbsp;- Inactive</i> : "";
                      var val = eachDevice.serial_number + "(" + eachDevice.nick_name + ")";
                      return <MenuItem key={eachDevice.serial_number} value={eachDevice.serial_number}>
                        <Checkbox checked={filterDevice.indexOf(eachDevice.serial_number) > -1} />
                        {val}{x}
                      </MenuItem>;
                    })}
                  </Select>
                </FormControl>

              </GridItem>
            </GridContainer>

            <div style={{ height: "25px" }}>&nbsp;</div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for transaction types */}
                  <InputLabel id="demo-simple-select-helper-label">Transaction Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: '100%' }}
                    multiple
                    value={filterTransactionType}
                    onChange={handleTransTypeChange}
                    input={<OutlinedInput label="Transaction Type" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >

                    <MenuItem key="Sale" value="SALE">
                      <Checkbox checked={filterTransactionType.indexOf("SALE") > -1} />
                      Sale
                    </MenuItem>

                    <MenuItem key="Void" value="VOID">
                      <Checkbox checked={filterTransactionType.indexOf("VOID") > -1} />
                      Void
                    </MenuItem>

                    <MenuItem key="Refund" value="RETURN">
                      <Checkbox checked={filterTransactionType.indexOf("RETURN") > -1} />
                      Refund
                    </MenuItem>

                    <MenuItem key="All" value="All">
                      <Checkbox checked={filterTransactionType.indexOf("All") > -1} />
                      All
                    </MenuItem>
                  </Select>
                </FormControl>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select payment results */}
                  <InputLabel id="payres">Payment Result</InputLabel>
                  <Select
                    labelId="payres"
                    id="payres"
                    style={{ width: '100%' }}
                    multiple
                    value={filterPaymentRes}
                    onChange={handlePaymentResChange}
                    input={<OutlinedInput label="Payment Result" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Approved" value="Approved">
                      <Checkbox checked={filterPaymentRes.indexOf("Approved") > -1} />
                      Approved
                    </MenuItem>
                    <MenuItem key="Declined" value="Declined">
                      <Checkbox checked={filterPaymentRes.indexOf("Declined") > -1} />
                      Declined
                    </MenuItem>
                  </Select>

                </FormControl>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} onClick={handleSearch} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <Button id="btnGreenEdit" onClick={() => { handelExport(); setExportButtonLabel(<CircularProgress style={{ color: 'white' }} id="loginProgress" ></CircularProgress>) }} variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', height: '55px' }} className={classes.button}>
                      {exportButtonLabel}
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            <GridContainer>
            </GridContainer>
            
            {/* Display transaction list  */}
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Status", "Payment Method", "Amount", "Type", "View", "Send Receipt", "Created at", "Time"]}
              tableData={tableData}
            />
          </CardBody>
          <div>
            <div style={{ float: "left" }}>
              <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }}>
                
                {/* Dropdown for select how many transaction list show in single page */}
                <InputLabel id="demo-simple-select-helper-label">Rows per page</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={rowPerPage}
                  label="Row per page"
                  className={classes.rowPerPage}
                  onClick={addclass}
                  onChange={(e) => { setRowPerPage(e.target.value); handelPagination(e, 1, e.target.value); }}
                >
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ float: "right", marginBottom: "10px", marginRight: "10px" }}>
              <Pagination className={classes.root} classes={{ ul: classes.ul }} style={{ color: "red" }} count={(totalPages == 0)?1:totalPages} defaultPage={currentPages} onChange={(e, v) => { handelPagination(e, v, rowPerPage); }} onClick={addclass} />
            </div>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}