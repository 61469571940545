/*
  @page Device manager
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};
function getStyles(category, categoryID, theme) {
  return {
    fontWeight:
      categoryID.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function Settings page logic
*/
var seletedCheckBox = {};
export default function TableList() {
  const [tableData, setTableData] = useState(false);
  const [deviceId, setDeviceId] = useState(0);
  const [itemDeleteDailog, setItemDeleteDailog] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [multiCatShow, setMultiCatShow] = useState(false);
  const [formValidationError, setFormValidationError] = useState(true);
  const [openEditDevice, setOpenEditDevice] = React.useState(false);
  const [nickname, setNickname] = React.useState("");
  const [serialNumber, setSerialNumber] = React.useState("");
  const [deviceType, setDeviceType] = React.useState("");
  const [pricingType, setPricingType] = React.useState("Fixed Price");
  const [unit, setUnit] = React.useState("");
  const [showInPOS, setShowInPOS] = React.useState(true);
  const [category, setcategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [multiSelectedCategory, setMultiSelectedCategory] = React.useState([]);
  const [modifire, setModifire] = React.useState([]);
  const [selectedModifire, setSelectedModifire] = React.useState([]);
  const [tax, setTaxName] = React.useState([]);
  const [selectedTaxes, setSelectedTaxes] = React.useState([]);
  const [sellPrice, setSellPrice] = React.useState("");
  const [costPrice, setCostPrice] = React.useState("");
  const [sku, setSKU] = React.useState("");
  const [posId, setPosId] = React.useState("");
  const [accessPage, setAccessPage] = useState(1);

  const clearVal = () => {
    setDeviceId(0);
    setNickname("");
    setDeviceType("");
    setSerialNumber("");
  }

  /*
    @function For nickname validation
  */
  const validateVal = () => {
    var errorMessageString = '';

    if (nickname === '' || nickname === null)
      errorMessageString += 'Please Enter Nick Name.';
    setErrorMsg(errorMessageString);
    if (errorMessageString === '') {
      setError(false);
      return true;
    }
    else {
      setError(true);
      return false;
    }
  }

  /*
    @const Get devices list
  */
  const getItemList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_device_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachDevice) => {
        userArr.push(["", eachDevice.serial_number, <div>{eachDevice.nick_name} </div>,<div><button id="greenediticon" style={{ cursor: 'pointer', background: 'none', color: '#ffa726', border: 'none' }} onClick={() => { setDeviceId(eachDevice.id); setNickname(eachDevice.nick_name); setOpenEditDevice(true); }}><EditIcon style={{height:'20px'}}></EditIcon></button></div>, eachDevice.device_type, (eachDevice.is_approve) ? "Active" : "Deactivated"]);
      });
      setTableData(userArr);

    }).catch(error => {     
      return error;
    });
  }

  /*
    @const nickname dialog submit
  */
  const handleEditName = () => {

    const data = {
      id: deviceId,
      nick_name: nickname,
    };
    setOpenEditDevice(false);
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'update_nickname', data, {
      headers: headers
    }).then(response => {
      clearVal();
      getItemList();
      setSuccess(true);
      setSuccessMsg("Nickname Updated Successfully!");

    }).catch(error => {
      clearVal();
      getItemList();
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });
  }

  /*
    @const delete item
  */
  const deleteItem = (itemID) => {
    setTableData(false);
    setItemDeleteDailog(false);
    const data = { id: itemID };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_item', data, {
      headers: headers
    }).then(response => {
      getItemList();
      setDeviceId(0);
      if (response.data == 1) { //Check item was deleted or not and display error or success message
        setSuccess(true);
        setSuccessMsg("Item Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
      }
    }).catch(error => {
      getItemList();
      setDeviceId(0);
      setError(true);
      setErrorMsg("Some Error occure!!");
      return error;
    });

  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setItemDeleteDailog(false);
    setError(false);
    setSuccess(false);
  };

  /*
   @function Save devices
  */
  const saveDevice = () => {
    if (validateVal() === true) { //Check validation
      const data = {
        nick_name: nickname,
        serial_number: serialNumber,
        device_type: deviceType
      };
      setTableData(false);
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
      }
      data.parent_id = localStorage.getItem('parent_id');
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl + 'save_device', data, {
        headers: headers
      }).then(response => {
        clearVal();
        getItemList();
        setSuccess(true);
        setSuccessMsg("Device Added Successfully!");
      }).catch(error => {
        getItemList();
        setError(true);
        setErrorMsg("Some Error Occure!!");
        return error;
      });
    }

  }

  const classes = useStyles();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  useEffect(() => {
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_device_manager === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    // getCategoryList();
    getItemList();

  }, []);

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Device List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }
  
  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Device List</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>

        {/* 
          Display error message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
          Display success message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Device List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            {/* 
              Display device list
            */}
            <Table
              tableHeaderColor="primary"
              tableHead={["", "Serial Number", "Nickname","Action", "Device Type", "Status"]}
              tableData={tableData}
            />
          </CardBody>
        </Card>

        {/* 
          *Delete Item  popup start
        */}
        <Dialog
          open={itemDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this item?"}</DialogTitle>
          <DialogActions>
            <Button onClick={() => { setDeviceId(0); setItemDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button onClick={(e) => { deleteItem(itemId) }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* 
          *Delete Item  popup end
        */}




        {/* 
          *Edit Item form start 
        */}
        <Dialog open={openEditDevice} onClose={() => { setOpenEditDevice(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Nickname</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Enter Nick Name"
              onChange={(e) => setNickname(e.target.value)}
              value={nickname}
              fullWidth
            />
            <div style={{ clear: 'both' }}></div>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenEditDevice(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenEdit" onClick={() => { handleEditName() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* 
          *Edit Item form end 
        */}

      </GridItem>
    </GridContainer>
  );
}
