/*
  @page Settings
*/

//Import require libraries
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch } from "@material-ui/core";
import Helper from "./../../util/Helper";
import DatePicker from "react-datepicker";
import InputLabel2 from '@mui/material/InputLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@mui/material/TextField';
/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

/*
  @const State array
*/
const actions = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon	Salem", value: "Oregon	Salem" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "US Virgin Islands", value: "US Virgin Islands" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wiscons", value: "Wiscons" },
  { label: "Wyoming", value: "Wyoming" },
];

/*
  @function Settings page logic
*/
export default function UserProfile() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [settingData, setStttingData] = useState(false);
  const [sessionTimeout, setSesstionTimeout] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [sign_footer_line, setSignFooterLine] = useState(null);
  const [footer_line, setFooterLine] = useState(null);
  const [batchAtoEmail, setBatchAutoEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [merchant_id, setMerchantID] = useState("");
  const [merchant_cat_code, setMerchantCatCode] = useState("");
  const [sendIvuData, setSendIvuData] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = React.useState('');

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  /*
    @function Setting form submit
  */
  function handleSubmit(event) {
    event.preventDefault();

    if (!validURL(String(website)) && website != "" && website != null) // Check web url is valid or not
    {
      setError(true);
      setErrorMsg("Please Enter Valid URL");
      return false;
    }
    if(passcode.length < 4)
    {
      setError(true);
      setErrorMsg("passcode must be 4 digit long");
      return false;
    }
    var data = "";
    if (state === "Puerto Rico") { // Check selected state is Puerto Rico or not
      data = {
        business_name: businessName,
        email: email,
        street_address: streetAddress,
        city: city,
        state: state,
        zip: zip,
        phone_number: phoneNumber,
        passcode: passcode,
        session_timeout: sessionTimeout,
        website: website,
        name: contactName,
        offline_push_days:offline_push_days
      };
    } else {
      data = {
        business_name: businessName,
        email: email,
        street_address: streetAddress,
        city: city,
        state: state,
        zip: zip,
        phone_number: phoneNumber,
        passcode: passcode,
        evertec_flag: "0",
        puerto_rico_number: "",
        session_timeout: sessionTimeout,
        website: website,
        name: contactName,
        offline_push_days:offline_push_days
      };
    }
    if (localStorage.getItem('token')) { // Check data is available in localstorage or not
      data.admin_token = localStorage.getItem('token');
      data.merchant_id = merchant_id;
      data.merchant_cat_code = merchant_cat_code;
    }

    /*
      @function For url validation
    */
    function validURL(str) {
      const regex = new RegExp(/^((https?):\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return regex.test(str);
    }
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'save_setting', data, {
      headers: headers
    }).then(response => {
      if (response) {
        setGetLogo(response.data.settingData.logo);
        if (response.data.error) { //Check data updated successfully or any error generate
          setErrorMsg(response.data.error);
          setError(true);
        } else {
          setSuccessMsg("Setting Updated Successfully!");
          setSuccess(true);
        }
      }
    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);      
      return error;
    });
  }


  /*
   @function Receipt layout form submit
  */
  function handleReceiptLayoutSubmit(event) {
    event.preventDefault();
    var formData = new FormData();
    if(logo)
    {
      formData.append("logo", logo); 
    }
    
    formData.append("sign_footer_line", (sign_footer_line) ? sign_footer_line : "");    
    formData.append("footer_line", footer_line);    
    formData.append("receipt_signature_footer", (signFooterSwitch)?1:0);    
    formData.append("auto_batch_email", (batchAtoEmail === false) ? "0" : batchAtoEmail);    
    formData.append("print_customer_name", (print_customer_name)?1:0);    
    formData.append("print_table_number", (print_table_number)?1:0);    
    formData.append("parent_id", localStorage.getItem('parent_id'));
    formData.append("location_id", localStorage.getItem('location_id'));
    
    const data = { sign_footer_line: (signFooterSwitch) ? sign_footer_line : "", footer_line: footer_line, receipt_signature_footer: signFooterSwitch, auto_batch_email: (batchAtoEmail === false) ? "0" : batchAtoEmail, print_customer_name: print_customer_name, print_table_number: print_table_number,logo:logo };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken'),
      'Content-Type': 'multipart/form-data'
    }
    axios.post(window.apiUrl + 'save_setting', formData, {
      headers: headers
    }).then(response => {
      if (response) {
        if (response.data.error) { //Check data updated successfully or any error generate
          setErrorMsg(response.data.error);
          setError(true);
        } else {
          setSuccessMsg("Setting Updated Successfully!");
          setSuccess(true);
          (signFooterSwitch) ? "" : setSignFooterLine("");
        }

      }

    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);
      
      return error;
    });
  }

  /*
   @function Report time form submit
  */
  function handleReportTimeSubmit(event) {
    event.preventDefault();
    let h = (parseInt(startDate.getHours()) == 0) ? "00" : startDate.getHours();
    let m = (parseInt(startDate.getMinutes()) == 0) ? "00" : startDate.getMinutes();
    var t = h + ":" + m + ":00";
    const data = { report_start_time: t };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'save_setting', data, {
      headers: headers
    }).then(response => {
      if (response) {
        if (response.data.error) { //Check data updated successfully or any error generate
          setErrorMsg(response.data.error);
          setError(true);
        } else {
          setSuccessMsg("Setting Updated Successfully!");
          setSuccess(true);
          (signFooterSwitch) ? "" : setSignFooterLine("");
        }
      }
    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);      
      return error;
    });


  }

  /*
   @function Report time form submit
  */
  function handleIVUSubmit(event) {
    event.preventDefault();
    const data = { evertec_flag: ivuSwitch, puerto_rico_number: taxIdNumber.replace("-", "").slice(0, 11), send_ivu_data: (ivuSwitch) ? sendIvuData : 0 };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'save_setting', data, {
      headers: headers
    }).then(response => {;
      if (response) {
        if (response.data.error) { //Check data updated successfully or any error generate
          setErrorMsg(response.data.error);
          setError(true);
        } else {
          setSuccessMsg("Setting Updated Successfully!");
          setSuccess(true);
        }
      }
    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);      
      return error;
    });
  }

  /*
    @function Other setting form submit
  */
  function handleOtherSettingSubmit(event) {
    event.preventDefault();
    const data = { ask_customer_name: ask_customer_name, ask_table_number: ask_table_number };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'save_setting', data, {
      headers: headers
    }).then(response => {
      if (response) {
        if (response.data.error) { //Check data updated successfully or any error generate
          setErrorMsg(response.data.error);
          setError(true);
        } else {
          setSuccessMsg("Setting Updated Successfully!");
          setSuccess(true);
        }
      }
    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);      
      return error;
    });
  }

  /*
    @const Page styles
  */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
      background: state.isSelected ? '#77a6ff' : '#FFF',
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)"

    }),

    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#77a6ff"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#4586fd"
      },

    }),


  }

  /*
    @function Login page logic
  */
  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [ivuSwitch, setIvuSwitch] = useState(true);
  const [signFooterSwitch, setSignFooterSwitch] = useState(true);
  const [print_customer_name, setPrintCustomerName] = useState(true);
  const [print_table_number, setPrintTableNumber] = useState(true);
  const [ask_customer_name, setAskCustomerName] = useState(true);
  const [ask_table_number, setAskTableNumber] = useState(true);  
  const [logo, setLogo] = useState("");  
  const [getLogo, setGetLogo] = useState("");  
  const [accessPage, setAccessPage] = useState(1);  
  const [offline_push_days, setOfflinePushDays] = useState("");  
 
  const deleteCompanyLogo = () =>
  {
    document.getElementById('uploadLogoBtn').style.display = 'block';
    document.getElementById('cancelLogoBtn').style.display = 'block';
    document.getElementById('logoImage').style.display = 'none';
    document.getElementById('deleteLogoBtn').style.display = 'none';
  }

  const cancelCompanyLogo = () =>
  {
    document.getElementById('uploadLogoBtn').style.display = 'none';
    document.getElementById('cancelLogoBtn').style.display = 'none';
    document.getElementById('logoImage').style.display = 'block';
    document.getElementById('deleteLogoBtn').style.display = 'block';
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  
  /*
    @get Merchant setting start
  */
  useEffect(() => {

    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_settings_menu === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_setting', data, { //Get data from api
      headers: headers
    }).then(response => {
      setAskCustomerName(response.data.settingData.ask_customer_name);
      setAskTableNumber(response.data.settingData.ask_table_number);
      setPrintCustomerName(response.data.settingData.print_customer_name);
      setPrintTableNumber(response.data.settingData.print_table_number);
      setEmail(response.data.settingData.email);
      setBusinessName(response.data.settingData.business_name);
      setStreetAddress(response.data.settingData.street_address);
      setCity(response.data.settingData.city);
      setZip(String(response.data.settingData.zip).padStart(5, '0'));
      setState(response.data.settingData.state);
      setPhoneNumber(response.data.settingData.phone_number);
      setTaxIdNumber(response.data.settingData.puerto_rico_number);
      setStttingData(true);
      setSesstionTimeout(response.data.settingData.session_timeout);
      setPasscode(response.data.settingData.passcode);
      setIvuSwitch(response.data.settingData.evertec_flag);
      setSignFooterLine(response.data.settingData.sign_footer_line);
      setFooterLine(response.data.settingData.footer_line);
      setSignFooterSwitch(response.data.settingData.receipt_signature_footer);
      setBatchAutoEmail(response.data.settingData.auto_batch_email);
      setWebsite(response.data.settingData.website);
      setMerchantID(response.data.settingData.merchant_id);
      setSendIvuData(response.data.settingData.send_ivu_data);
      setMerchantCatCode(response.data.settingData.merchant_cat_code);
      setContactName(response.data.settingData.name);      
      setGetLogo(response.data.settingData.logo);      
      setOfflinePushDays(response.data.settingData.offline_push_days);      
      let report_start_time = response.data.settingData.report_start_time.split(":");
      let t = new Date();
      t.setHours(report_start_time[0]);
      t.setMinutes(report_start_time[1]);
      t.setSeconds(0);
      setStartDate(t)
    }).catch(error => {     
      return error;
    });

  }, []);

  /*
    @get Merchant setting end
  */
  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Your Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }
  var purtoNumberHtml = "";
  if (state === "Puerto Rico") { //Check selected state was Puerto Rico or not
    purtoNumberHtml = <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>

          {/**
            *Form for IVU Control settings
          */}
          <form onSubmit={handleIVUSubmit}>
            <CardHeader className="cardheader" color="warning">
                   <h4 className={classes.cardTitleWhite}>IVU Control</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  Do you want to generate IVU Control Number?
                  <Switch
                    checked={ivuSwitch}
                    onChange={(e) => { setIvuSwitch(e.target.checked) }}
                    id="ivuswitch"
                  />
                </GridItem>

              </GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Tax Id Number"
                  id="taxIdnumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={Helper.formatIVUControlNumber(taxIdNumber)}
                  onChange={(e) => setTaxIdNumber(e.target.value)}
                />
              </GridItem>
              <div style={{ display: (ivuSwitch) ? 'block' : 'none' }}>
                <GridItem xs={12} sm={12} md={12}>
                  Send IVU Data?
                  <Switch
                    checked={sendIvuData}
                    onChange={(e) => { setSendIvuData(e.target.checked) }}
                    id="sendIvuSwitch"
                  />
                </GridItem>
              </div>
            </CardBody>

            <CardFooter>
              <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>

    </GridContainer>
  }

  if (!settingData) { //Check if setting data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Your Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>Loading...</h3>
                  <CircularProgress id="loginProgress" ></CircularProgress>
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <div>
      {/* 
        Display error message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={Error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            {/*
              * Form business information settings
            */}
            <form onSubmit={handleSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Your Settings</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant ID"
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{ maxLength: 24 }}
                      value={merchant_id}
                      onChange={(e) => { (localStorage.getItem('role') === "admin") ? setMerchantID(e.target.value) : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant Category Code"
                      id="m_cat_code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{ maxLength: 4 }}
                      value={merchant_cat_code}
                      onChange={(e) => { (localStorage.getItem('role') === "admin") ? setMerchantCatCode(e.target.value) : "" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Business Name"
                      id="businessname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Street Address"
                      id="streetaddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={streetAddress}
                      onChange={(e) => setStreetAddress(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer styles={{ marginTop: 50 }}>
                  <GridItem xs={12} sm={12} md={4}>

                    <Select
                      value={actions.filter(function (action) {
                        return action.value === state;
                      })}
                      onChange={(e) => { setState(e.value); }} options={actions}
                      styles={customStyles}
                    />

                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Zip Code"
                      inputProps={{ max: 5, type: 'number' }}
                      id="zipcode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={String(zip)}
                      onChange={(e) => (e.target.value.length > 5) ? zip : setZip(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="offline push days"                                            
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={offline_push_days}
                      onChange={(e) => setOfflinePushDays(e.target.value) }
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number"
                      id="phonenumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={Helper.formatPhoneNumber(phoneNumber)}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                   <CustomInput
                      labelText="Email Address"
                      id="filled-disabled"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={email}      
                      variant="filled"
                      onChange={(e) => { (localStorage.getItem('role') === "admin") ? setEmail(e.target.value) : "" }}
                    />                   
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Session Timeout (in Min)"
                      id="session_timeOut"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={sessionTimeout}
                      onChange={(e) => setSesstionTimeout(e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                  
                      <CustomInput
                      labelText="Passcode"
                      inputProps={{ max: 4, type: 'number' }}
                      id="passcode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={String(passcode)}                      
                      onChange={(e) => (e.target.value.length > 4) ? passcode : setPasscode(e.target.value)}
                    />
                    
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Website"
                      id="web"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>                                  
                      <CustomInput
                      labelText="Contact Name"
                      id="filled-disabled"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={contactName}      
                      variant="filled"
                      onChange={(e) => { (localStorage.getItem('role') === "admin") ? setContactName(e.target.value) : "" }}
                    /> 
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>

      {/*
        * display IVU form settings
      */}
      {purtoNumberHtml}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            {/**
              * Form receipt layout settings
             */}
            <form onSubmit={handleReceiptLayoutSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Receipt Layout</h4>
              </CardHeader>

              <CardBody>
                <GridItem xs={12} sm={12} md={6}>
                <InputLabel2 id="demo-simple-select-label" style={{color:"rgba(0, 0, 0, 0.87)",fontWeight: "300",lineHeight:"1.5em",fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",fontSize:"14px"}}>
                    {(getLogo)?"Company Logo":"Company Logo"}
                </InputLabel2>
                <span style={{fontSize:"10px"}}> [Image size should be <b>300px X 65px</b>]</span>
                
                <div style={{display:'block',marginTop:'10px'}}>
                
                  <div >
                      <input  
                        type="file" 
                        multiple accept="image/*" 
                        style={{ display: 'none' }}
                        onChange={(e)=>{setLogo(e.target.files[0]);setGetLogo(URL.createObjectURL(e.target.files[0]));document.getElementById('logoImage').style.display = 'block';document.getElementById('deleteLogoBtn').style.display = 'block';document.getElementById('uploadLogoBtn').style.display = 'none';document.getElementById('cancelLogoBtn').style.display = 'none';}}
                        id="contained-button-file"
                      />
                      {(getLogo)?
                      <div style={{display:"block"}}>
                        <div style={{display:"flex"}}>
                          
                        <img id="logoImage" style={{display:(getLogo)?"block":"none",height:"65px",objectFit:"contain"}} src={getLogo}></img>                       
                        <Button onClick={deleteCompanyLogo} style={{marginTop:"20px",backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} id="deleteLogoBtn" variant="contained" component="span">
                         <DeleteForeverIcon></DeleteForeverIcon>
                        </Button>
                        </div>
                        <div style={{display:"flex"}}>                     
                          <label htmlFor="contained-button-file">
                              <Button id="uploadLogoBtn" style={{display:"none",marginTop:"20px"}} variant="contained" color="success" component="span">
                                Upload
                              </Button>
                          </label>
                          <label>
                              <Button onClick={cancelCompanyLogo} id="cancelLogoBtn" style={{display:"none",marginTop:"20px",marginLeft:"10px"}} variant="contained" color="warning" component="span">
                                Cancel
                              </Button>
                          </label>
                        </div> 
                      </div>
                      :
                      <label htmlFor="contained-button-file">
                          <Button id="uploadLogoBtn" style={{marginTop:"20px"}} variant="contained" color="success" component="span">
                            Upload
                          </Button>
                      </label>
                      }
                     
                     
                      
                      
                        
                  </div>
                </div>
                </GridItem>
                <br></br>
                <GridItem xs={12} sm={12} md={6}>
                  Show Signature Line
                  <Switch
                    checked={signFooterSwitch}
                    onChange={(e) => { (e.target.checked) ? document.getElementById("signFooter").style.display = "block" : document.getElementById("signFooter").style.display = "none"; setSignFooterSwitch(e.target.checked) }}
                  />
                  <div id="signFooter" style={{ display: (signFooterSwitch) ? "block" : "none" }}>
                    <CustomInput
                      labelText="Signature Footer Line Text"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={sign_footer_line}
                      onChange={(e) => setSignFooterLine(e.target.value)}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Footer Line"
                    id="taxidnumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={footer_line}
                    onChange={(e) => setFooterLine(e.target.value)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  Print Customer Name on Receipt
                  <Switch
                    checked={print_customer_name}
                    onChange={(e) => { setPrintCustomerName(e.target.checked) }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  Print Table Number on Receipt
                  <Switch
                    checked={print_table_number}
                    onChange={(e) => { setPrintTableNumber(e.target.checked) }}
                  />
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            {/**
              * Form receipt layout settings
             */}
            <form onSubmit={handleReceiptLayoutSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Batch Setting</h4>
              </CardHeader>

              <CardBody>
                
                <GridItem xs={12} sm={12} md={6}>
                  Get Email Notification Every Batch
                  <Switch
                    checked={(batchAtoEmail === "0") ? false : batchAtoEmail}
                    onChange={(e) => { (e.target.checked) ? document.getElementById("emailSec").style.display = "block" : document.getElementById("emailSec").style.display = "none"; setBatchAutoEmail(e.target.checked) }}
                  />
                  <div id="emailSec" style={{ display: (batchAtoEmail === false || batchAtoEmail === "0") ? "none" : "block" }}>
                    <CustomInput
                      labelText="Enater Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={(batchAtoEmail === true || batchAtoEmail === false || batchAtoEmail === "0") ? "" : batchAtoEmail}
                      onChange={(e) => setBatchAutoEmail(e.target.value)}
                    />
                  </div>
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>
          </Card>

          <Card>

            {/**
              * Form default report time
             */}
            <form onSubmit={handleReportTimeSubmit} enctype="multipart/form-data">
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Report Settings</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: "20px" }}>

                  <div class="halfhourtimepicker">
                    <InputLabel2 id="demo-simple-select-label">Default Start Time</InputLabel2>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>

                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>
          </Card>


          <Card>

            {/**
              * Form other settings
             */}
            <form onSubmit={handleOtherSettingSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Other Settings</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={6}>
                  Prompt Customer Name
                  <Switch
                    checked={ask_customer_name}
                    onChange={(e) => { setAskCustomerName(e.target.checked) }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  Prompt Table Number
                  <Switch
                    checked={ask_table_number}
                    onChange={(e) => { setAskTableNumber(e.target.checked) }}
                  />
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>
          </Card>

        </GridItem>
      </GridContainer>
    </div>
  );
}

