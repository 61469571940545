/*
  @page item sales report
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import RightIcon from '@material-ui/icons/ChevronRight';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import axios from 'axios';
/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    width:"100%",
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  tableheader:{
    textAlign:"left",
    paddingLeft:"20px",
    position: "sticky",
    left: 0,
    backgroundColor: '#E5EFFF',
    marginBottom: 0,
    minWidth:"300px", 
    borderRight:"1px solid gray"
  },
  tableheaders:{  
    minWidth:"126px",
    backgroundColor: '#E5EFFF',
  },
  tableoddtr:{
    height:"45px",
  },
  tableoddtrtotal:{
    height:"45px",
    borderTop:"1px solid gray"
  },
  tableeventr:{
    height:"45px",
  },
  tableoddtrth:{
    backgroundColor:"#FFFFFF",
    position: "sticky",
    left: 0,
    borderRight:"1px solid gray"
  },
  tableoddtrthtotal:{
    backgroundColor:"#f2fcee",
    position: "sticky",
    left: 0,
    borderRight:"1px solid gray"
  },
  tableoddtrtd:{
    backgroundColor:"#FFFFFF",
    textAlign:"right"
  },
  tableoddtrtdtotal:{
    backgroundColor:"#f2fcee",
    textAlign:"right"
  },
  tableeventrth:{
    backgroundColor:"#f3f4f5",
    position: "sticky",
    left: 0,
    borderRight:"1px solid gray"
  },
  tableeventrtd:{
    backgroundColor:"#f3f4f5",
    textAlign:"right"
  },
  startDateBtn :{   
    width: "100% !important",
    paddingLeft:"1%",
    '& div:nth-child(1)':{
      width: "100% !important",
    }     
  },
  buttonSearch:{
    width: "100%",
    marginLeft: "8px !important",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function sales report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [visibleUncategorizeddata, setVisibleUncategorizeddata] = React.useState([]);
  const [rowVisibility, setRowVisibility] = React.useState(false);
  const [visibleItemOneData, setVisibleItemOneData] = React.useState(false);
  const [visibleItemTwoData, setVisibleItemTwoData] = React.useState(false);
  const [visibleItemThreeData, setVisibleItemThreeData] = React.useState(false);
  const [dateRange, setDateRange] = React.useState('');
  const [selectEmployee, setEmployee] = React.useState('');
  const [selectDevice, setDevice] = React.useState('');
  const [accessPage, setAccessPage] = useState(1);  
  useEffect(() => {    

 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0)
      {
        setAccessPage(0);
        return;
      }
    }	

    getItemSalesReport();
  }, []);

  /*
    @Const search filtered data
  */
  const handleSearch = () => { }
  
  /*
    @Const handle date filter
  */
  const dateChange = (event) => {
    setDateRange(event.target.value);
  };
  
  /*
    @Const handle employee filter
  */
  const employeeChange = (event) => {
    setEmployee(event.target.value);
  };
  
  /*
    @Const handle device filter
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
  };


  const getItemSalesReport = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'item_sales_report', data, {
      headers: headers
    }).then(response => {
      setTableData(response.data);
    }).catch(error => {
      return error;
    });
  }

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Reporting - Revenue Item Sales</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>You Don't have access of this page!!</h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Revenue Item Sales</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          {/* 
            * Filter options
          */}
          <CardBody>
            <GridContainer>
                <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={dateRange}
                      label="Date Range"
                      onChange={dateChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>Today</MenuItem>
                      <MenuItem value={2}>Yesterday</MenuItem>
                      <MenuItem value={3}>This Week</MenuItem>
                      <MenuItem value={4}>Last Week</MenuItem>
                      <MenuItem value={5}>Last 7 Days</MenuItem>
                      <MenuItem value={6}>This Month</MenuItem>
                      <MenuItem value={7}>Last Month</MenuItem>
                      <MenuItem value={8}>Last 30 Days</MenuItem>
                      <MenuItem value={9}>Last 3 Months</MenuItem>
                      <MenuItem value={10}>This Year</MenuItem>
                      <MenuItem value={11}>Last Year</MenuItem>
                      <MenuItem value={10}>Custom Date Range</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="giemployee" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Employees</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectEmployee}
                      label="Employees"
                      onChange={employeeChange}
                    >
                      <MenuItem value={0}>All Employees</MenuItem>
                      <MenuItem value={1}>Employee1</MenuItem>
                      <MenuItem value={2}>Employee2</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="gidevice" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Devices</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="chart"
                      value={selectDevice}
                      label="Devices"
                      onChange={deviceChange}
                    >
                      <MenuItem value={0}>All Devices</MenuItem>
                      <MenuItem value={1}>Device1</MenuItem>
                      <MenuItem value={2}>Device2</MenuItem>
                      <MenuItem value={3}>Device3</MenuItem>           
                      </Select>
                    </FormControl>
                  </GridItem>                
                </GridContainer>
              
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}} >
                    <div className={classes.startDateBtn} >
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker   
                                
                    label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>                
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                  <div className={classes.startDateBtn}>             
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker        
                    label="End Date"        
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}  style={{marginTop:"20px"}}>
                    <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                      Search
                    </Button>
                  </GridItem>
                </GridContainer><br></br><br></br>

            <GridContainer>
              {/* 
                * Display item sales report
              */}
            <div style={{width:"100%",height:"100px",paddingTop:"25px",backgroundColor:"#f2fcee",marginTop:"20px",display:"flex"}}>
                <div style={{width:"25%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Gross Sales</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$100</div>
                </div>
                <div style={{width:"25%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Net Sales</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$100</div>
                </div>
                <div style={{width:"25%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Amount Collected</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$100</div>
                </div>
                <div style={{width:"25%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Orders</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$100</div>
                </div>
              </div>
              <div style={{width:"100%",height: visibleUncategorizeddata ? '400px' : '330px',overflow:"auto",marginTop:"50px"}}>
                <table style={{width:"100%"}}>
                  <thead style={{backgroundColor: '#dee0e1',position:"sticky",top:"0",zIndex:"99"}}>
                    <tr>
                      <th style={{borderRight:"1px solid gray"}} rowSpan={2} className={classes.tableheader}><span>Name</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Sold</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Refunded</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Exchanged</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Net Sold</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Item Gross Sales</span></th>
                      <th colSpan={3} className={classes.tableheaders}><span>Modifier Gross Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Gross Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Discounts</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Repayments</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Refunds</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Exchanges</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Item Net Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Modifier Net Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Net Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>% Net Sales</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Avg Item Size</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>COGS</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Gross Profit</span></th>
                      <th rowSpan={2} className={classes.tableheaders}><span>Gross Profit Margin</span></th>
                    </tr>
                    <tr>
                      <th  className={classes.tableheaders}>Name</th>
                      <th  className={classes.tableheaders}>Sold</th>
                      <th  className={classes.tableheaders}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                       Object.keys(tableData).map(k => {                       
                        
                        
                        var tblHtml = [];                        
                        tblHtml.push(<tr className={classes.tableoddtr}>
                        <th onClick={() => { setRowVisibility(!rowVisibility)}} className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",display:"flex"}}><div>{rowVisibility? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div style={{paddingTop:"5px"}}><b>{k}</b></div></button></th>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                        <td className={classes.tableoddtrtd}>{rowVisibility? " ":"$10"}</td>
                      </tr>);
                       tableData[k].map(eachItem => tblHtml.push(<tr className={classes.tableoddtr} style={{display: rowVisibility ? 'revert' : 'none'}}>
                       <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>{eachItem.item_name}</button></th>
                       <td className={classes.tableoddtrtd}>${eachItem.item_sales_total}</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                       <td className={classes.tableoddtrtd}>$10.00</td>
                     </tr>));
                      
                        return(tblHtml);
                      
                    })
                    }
                    
                    {/* <tr className={classes.tableoddtr} style={{display: visibleUncategorizeddata ? 'revert' : 'none'}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>ttt</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleUncategorizeddata ? 'revert' : 'none'}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Item A</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleUncategorizeddata ? 'revert' : 'none'}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Sale</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleUncategorizeddata ? 'revert' : 'none',borderTop:"1px solid gray"}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>total</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableeventr}>
                      <th onClick={() => { setVisibleItemOneData(!visibleItemOneData) }} className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",display:"flex"}}><div>{visibleItemOneData? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div style={{paddingTop:"5px"}}><b>Burgers</b></div></button></th>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemOneData? " ":"$10"}</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemOneData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Hamburger</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemOneData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Chicken Sandwich</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemOneData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Total</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr}>
                      <th onClick={() => { setVisibleItemTwoData(!visibleItemTwoData) }} className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",display:"flex"}}><div>{visibleItemTwoData? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div style={{paddingTop:"5px"}}><b>Salads</b></div></button></th>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                      <td className={classes.tableoddtrtd}>{visibleItemTwoData? " ":"$10"}</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleItemTwoData ? 'revert' : 'none',}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Angus Steak Kabob Salad</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleItemTwoData ? 'revert' : 'none',}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Salad</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtr} style={{display: visibleItemTwoData ? 'revert' : 'none',}}>
                      <th className={classes.tableoddtrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Total</button></th>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                      <td className={classes.tableoddtrtd}>$10.00</td>
                    </tr>
                    <tr onClick={() => { setVisibleItemThreeData(!visibleItemThreeData) }} className={classes.tableeventr}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",display:"flex"}}><div>{visibleItemThreeData? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div style={{paddingTop:"5px"}}><b>Sweets</b></div></button></th>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                      <td className={classes.tableeventrtd}>{visibleItemThreeData? " ":"$10"}</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemThreeData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Cinnamon Rolls</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemThreeData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Brownie</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableeventr} style={{display: visibleItemThreeData ? 'revert' : 'none',}}>
                      <th className={classes.tableeventrth}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",width:"100%",backgroundColor:"transparent",border:"0",paddingLeft:"30px"}}>Total</button></th>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                      <td className={classes.tableeventrtd}>$10.00</td>
                    </tr>
                    <tr className={classes.tableoddtrtotal} >
                      <th className={classes.tableoddtrthtotal}><button id="btnItemSalesReportTblTr" style={{textAlign:"left",backgroundColor:"transparent",width:"100%",border:"0",paddingLeft:"20px"}}><b>Total</b></button></th>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                      <td className={classes.tableoddtrtdtotal}>$10.00</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </GridContainer>

          </CardBody>
        </Card>
      </GridItem>


    </GridContainer>
  );
}
